import React from "react";
import { Link } from "react-router-dom";
import _ from "../../i18n";

import { getUserNameForAvatar, generateFullName } from "../../utils";

import {
  TrelloIcon,
  CalendarIcon,
  SliderIcon,
  SettingsIcon,
  WeightLiftingIcon,
  AlertTriangleIcon,
} from "../common/Icons";
import { Tooltip } from "antd";

import "./Item.local.scss";

const Item = ({ lng, item, key, onShowDevCard ,preferences}) => {
  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className="avatar_not_exist">
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  return (
    <div key={key} className={`card`}>
      {item.deletedAt ? (
        <div className="alert">
          <div className="message">{`${_("innactifSince")} ${
            item.deletedAt
          }`}</div>
          <AlertTriangleIcon />
        </div>
      ) : !item.role ?(
        <div className="alert">
          <div className="message">{_("noRoleConfigured")}</div>
          <SettingsIcon fill="#EB5757"/>
        </div>
      ) : ""}
      <div className={item.deletedAt ? "disabled" : ""}>
        <div className="avatar">
          {renderAvatar(item.avatarUrl, item.firstName, item.lastName)}
          {!item.deletedAt && preferences && preferences.allowCollabToDevCard==="1" && (
            <div
              className={`tag-bottom ${item.dcCount > 0 && "display"} `}
              onClick={() => onShowDevCard(item)}
            >
              <WeightLiftingIcon size={13} /> {item.dcCount}
            </div>
          )}
        </div>
        <div className="title">{item.name}</div>
        <div className="switch">
          <div
            className="total"
            style={{
              color:
                parseFloat(item.budgetedHours.released) >
                  parseFloat(item.budgetedHours.affected) &&
                parseFloat(item.budgetedHours.affected) > 0 &&
                parseFloat(item.budgetedHours.released) > 0
                  ? "#FFFFFF"
                  : "#6D7F92",
            }}
          >
            {parseFloat(item.budgetedHours.affected).toFixed(2)} h
          </div>
          <div className="card_progress">
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="card_progress_container">
                <div
                  className="card_progress_bar"
                  style={{
                    width:
                      parseFloat(item.budgetedHours.affected) > 0 &&
                      parseFloat(item.budgetedHours.released) > 0
                        ? parseFloat(item.budgetedHours.released) >
                          parseFloat(item.budgetedHours.affected)
                          ? `100%`
                          : `${
                              (parseFloat(item.budgetedHours.released) * 100) /
                              parseFloat(item.budgetedHours.affected)
                            }%`
                        : 0,
                    background:
                      parseFloat(item.budgetedHours.released) >
                      parseFloat(item.budgetedHours.affected)
                        ? "#DE4848"
                        : "#06D9B1",
                  }}
                ></div>
                <div
                  className="card_progress_index"
                  style={{
                    width: "fit-content",
                    color:
                      parseFloat(item.budgetedHours.released) >
                      parseFloat(item.budgetedHours.affected)
                        ? "#DE4848"
                        : "#02AF8E",
                  }}
                >
                  <span>
                    {parseFloat(item.budgetedHours.released).toFixed(2)} h
                  </span>
                  <span>{_("relized")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card_actions">
            <Tooltip
              key="tool-1"
              placement="top"
              title={_("details")}
              mouseLeaveDelay={0}
            >
              <Link to={`/${lng}/fiche-collaborator/${item.id}`}>
                <div className="action">
                  <TrelloIcon />
                </div>
              </Link>
            </Tooltip>
            <Tooltip
              key="tool-2"
              placement="top"
              title={_("calendar")}
              mouseLeaveDelay={0}
            >
              <Link to={`/${lng}/fiche-collaborator/${item.id}?tab=CALENDAR`}>
                <div className="action">
                  <CalendarIcon />
                </div>
              </Link>
            </Tooltip>
            <Tooltip
              key="tool-2"
              placement="top"
              title={_("Planner")}
              mouseLeaveDelay={0}
            >
              <Link to={`/${lng}/fiche-collaborator/${item.id}?tab=PLANNER`}>
                <div className="action">
                  <CalendarIcon />
                </div>
              </Link>
            </Tooltip>
            <Tooltip
              key="tool-1"
              placement="top"
              title={_("capacityPlaning")}
              mouseLeaveDelay={0}
            >
              <Link
                to={`/${lng}/fiche-collaborator/${item.id}?tab=FOLDER_CAPACITY`}
              >
                <div className="action">
                  <SliderIcon />
                </div>
              </Link>
            </Tooltip>
            <Tooltip
              key="tool-2"
              placement="top"
              title={_("configuration")}
              mouseLeaveDelay={0}
            >
              <Link to={`/${lng}/collaborators-settings/${item.name}`}>
                <div className="action">
                  <SettingsIcon />
                </div>
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className="card_infos">
          <span>{_("totalConcernedFolder")}</span>
          <span>
            {item.folders.budgeted +
              (item.folders.nonBudgeted
                ? parseInt(item.folders.nonBudgeted, 10)
                : 0)}
          </span>
        </div>
        <div className="card_counters">
          <div className="counter">
            <span>{parseInt(item.folders.budgeted, 10)}</span>
            <span>{_("attributeds")}</span>
          </div>
          {/* <div className="counter">
          <span>{parseInt(item.folders.asGD, 10)}</span>
          <span>{_("GD")}</span>
        </div> */}
          <div className="counter">
            <span>
              {item.folders.nonBudgeted
                ? parseInt(item.folders.nonBudgeted, 10)
                : 0}
            </span>
            <span>{_("nonAttributeds")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
