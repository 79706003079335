import { useSelector } from "react-redux";
import _ from "../../../i18n";
import { CustumedMatrix } from "tamtam-components";
import { getCollaboratorsSuggestions, getVolumetryCliets } from "../../../api";
import { useQuery, useQueryClient } from "react-query";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FaSort } from "react-icons/fa";
import "./PrestationSuivi.local.scss";
import { getMonthShortLabelObject, useOnClickOutside, renderTeamYears } from "../../../utils";
import TTPPaginator from "../../common/TTPPaginator";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";
import { IconArrowRight } from "../../common/Icons";

const PrestationSuivi = ({ lng, hasAccess }) => {
  const { token, navCommunity, loggedAs, spaceAs } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [nbrClients, setNbrClients] = useState(0);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState( new Date().getFullYear());
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([{}]);
  const [selectedCollaborator, setSelectedCollaborator] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState({
    collaborator: selectedCollaborator?.value,
    pageSize: 10,
    paginationPage: 1,
    filterTab: [
      {
        id: "MONTHLY",
        label: "monthly",
        subTab: getMonthShortLabelObject(lng),
      },
      { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    ],
    period: { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    subPeriod: "01-01",
  });

  const ref = useRef();
  const scrollRef = useRef(null);
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const queryClient = useQueryClient();

  const fetchVolumetryClients = async () => {
    if (token && navCommunity && filter.collaborator) {
      try {
        setLoading(true);
        const filterSubPeriodStart =
          filter.subPeriod && filter.subPeriod.start
            ? filter.subPeriod.start
            : "01-01";
        const formattedDate = `${year}-${filterSubPeriodStart}`;
        const response = await getVolumetryCliets(
          token,
          navCommunity.id,
          formattedDate,
          filter,
        );
        setData(response.data.data.pagination);
        setNbrClients(response.data.nbResult ? response.data.nbResult : 0);
        setLoading(false);
        return response.data.data;
      } catch (e) {
        setLoading(false);
        throw new Error(e.message);
      }
    }
  };

  useQuery(
    ["getVolumetryCliets", token, navCommunity, filter],
    fetchVolumetryClients,
    {
      enabled: !!token && !!navCommunity,
    },
  );

  const fetchListCollaborators = async () => {
    if (token && navCommunity) {
      try {
        const response = await getCollaboratorsSuggestions(
          token,
          navCommunity.id
        );
        let data = response.data.data
          .filter((item) => item !== null && item.zone === 2)
          .map((t) => {
            return {
              value: t.id,
              label: t.name,
              grade: t.grade
            };
          });
        setSelectedCollaborator(data[0]);
        setFilter({ ...filter, collaborator: data[0].value });
        setCollaboratorOptions(data);
      } catch (e) {
        return null;
      }
    }
  }

  useQuery(
    ["getCollaborators", token, navCommunity],
    fetchListCollaborators
  );

  useEffect(() => {
    fetchVolumetryClients();
  }, [filter, year]);

  useEffect(() => {
    if (filter.period.id === "ANNUAL") {
      setFilter({ ...filter, subPeriod: "01-01" });
    }
  }, [filter.period.id]);

  const handleCollaboratorsChange = (item) => {
    const selected = collaboratorOptions.filter(c => c.value === parseInt(item));
    setSelectedCollaborator(selected[0]);
    setFilter({ ...filter, collaborator: parseInt(item) });
  };

  const billingRateLabel = selectedCollaborator?.grade === "SENIOR" 
    ? "14 €" 
    : selectedCollaborator?.grade === "MEDIOR" 
    ? "11.5 €" 
    : selectedCollaborator?.grade === "JUNIOR" 
    ? "8.5 €" 
    : selectedCollaborator?.grade === "ADMIN" 
    ? "5 €" 
    : "";

  const columnsClientsInformations = [
    {
      id: "period",
      label: "Période",
      isEditable: true,
      widthPercentage: 10,
    },
    {
      id: "type",
      label: "Type",
      isEditable: true,
      widthPercentage: 6,
    },
    {
      id: "volumetry",
      label: "Caractéristiques encodage",
      isEditable: true,
      widthPercentage: 14,
    },
    {
      id: "tasks",
      label: "Time Sheet",
      isEditable: true,
      widthPercentage: 26,
    },
    {
      id: "billing_rate",
      label: billingRateLabel,
      isEditable: true,
      widthPercentage: 6,
    },
    {
      id: "ceiling_compliance_1_6",
      label: "Plafond en respect de la norme 1/6",
      isEditable: true,
      widthPercentage: 26,
    }
  ];

  const filters = [
    loggedAs === "MANAGER" &&
      spaceAs === "MANAGER" && {
        type: "SELECT",
        options: {
          isSimple: true,
          isMulti: false,
          isClearable: true,
          placeholder: _("collaborator"),
          options: collaboratorOptions,
          values: filter.collaborator,
          action: handleCollaboratorsChange,
        },
      },
  ];

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={filter.pageSize}
        nbResult={nbrClients}
        paginationPage={filter.paginationPage}
      />
    );
  };

  const convertToHoursMinutes = (value) => {
    const hours = Math.floor(value).toLocaleString();
    const minutes = Math.round((value - Math.floor(value)) * 60);
    return `${hours}h ${minutes}m`;
  };

  const handleColumns = (column, line) => {
    switch (column.id) {
      case "period":
        return (
          <div className="colomn" style={{ margin: "0 auto", width: "100%" }}>
           {Object.keys(line.period).map((key, index) => (
              <div
                className="labled-tag no-border"
                key={index}
                style={{ textAlign: "center", marginLeft: 5, marginTop: 15 }}
              >
                {data.indexOf(line) === 0 && <span>{_(key)}</span>}
                {data.indexOf(line) > 0 ? (
                  <div >
                    {line.period && line.period[key]}
                  </div>
                ) : (
                  <></>
              )}
              </div>
            ))}
          </div>
        );
      case "type":
        return (
          <div className="colomn" style={{ margin: "0 auto", width: "100%" }}>
           {Object.keys(line.type).map((key, index) => (
              <div
                className="labled-tag no-border"
                key={index}
                style={{ textAlign: "center", marginLeft: 5, marginTop: 15 }}
              >
                {data.indexOf(line) === 0 && <span>{_(key)}</span>}
                {line.client_id === "%" ? (
                  expanded ? <>
                    <div style={{ marginTop: 10 }} />
                    <div style={{ marginTop: 10 }} />
                    <div style={{ marginTop: 10 }} />
                    <div
                      style={{
                        backgroundColor: "#dfdfdf",
                        marginTop: "10px",
                        fontSize: "11px",
                      }}
                    >
                      {line.type && convertToHoursMinutes(line.type[key])}
                    </div> 
                  </> : ""
                ) : (
                  <div >
                    {line.type && convertToHoursMinutes(line.type[key])}
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      case "tasks":
        return (
          <div
            className="colomn"
            style={{ display: "flex", justifyContent: "start", alignItems: "flex-end", padding: "15px 0"}}
          >
            {line.tasks && line.tasks.map((item, key) => (
              <div
                className="labled-tag no-border"
                key={key}
                style={{
                  justifyContent:
                    data.indexOf(line) === 0 ? "flex-end" : "center",
                  paddingLeft: "10px",
                  textAlign: "center"
                }}
              >
                {data.indexOf(line) === 0 && (
                  <span>
                    {item.label_fr.substr(0, 24).toLowerCase()}
                    {item.label_fr.length > 25 ? "." : ""}
                  </span>
                )}

                {line.client_id === "%" ? (
                  expanded ? <>
                    {item.enc_value ?
                      <abbr style={{ textDecoration: "none" }} title={`(${item.prest_value} - ${item.ts_value}) / ${item.prest_value}`}>
                        <div
                          style={{
                            marginTop: "10px",
                            color: item.enc_value > 0 ? "green" : "red",
                            backgroundColor: item.enc_value > 0 ? "#90ee90" : "#ff5e5e50"
                          }}
                        >
                          {item.enc_value}
                        </div>
                      </abbr> : <div style={{ marginTop: 10 }} />
                    }
                    {item.ts_value ?
                      <div style={{ marginTop: "10px" }}>
                        {item.ts_value}
                      </div> : <div style={{ marginTop: 10 }} />
                    }
                    {item.prest_value ?
                      <div style={{ marginTop: "10px" }}>
                        {item.prest_value}
                      </div> : <div style={{ marginTop: 10 }} />
                    }
                    <div
                        style={{
                          backgroundColor: "#dfdfdf",
                          marginTop: "10px"
                        }}
                      >
                      {convertToHoursMinutes(item.task_duration)}
                    </div> 
                    <div
                      style={{
                        backgroundColor: "#dfdfdf",
                        marginTop: "10px"
                      }}
                    >
                      {`${item.percentage } %`}
                    </div>
                  </> : ""
                ) : (
                  <div
                    style={{
                      backgroundColor: item.label_fr === "Valeur" && "#dfdfdf",
                    }}
                  >
                    {convertToHoursMinutes(item.task_duration)}
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      case "volumetry":
        return (
          <div className="colomn" style={{ margin: "15px 0" }}>
           {Object.keys(line.volumetry).map((key, index) => (
              <div
                className="labled-tag no-border"
                key={index}
                style={{ textAlign: "center", marginLeft: "6px", width: "100%" }}
              >
                {data.indexOf(line) === 0 && <span>{_(`${key}`)}</span>}
                {line.client_id === "%" ? (
                  expanded ? <>
                    <div
                      className={`${key === "total" ? "vol_label" : ""}`}
                      style={{ marginTop: "10px", fontSize: key === "total" && 10, padding: key === "total" && "0 5px" }}
                    >
                      {line.sum.volumetry.time_etalon_total[key] || ""}
                    </div>
                    <div 
                      className={`${key === "total" ? "vol_label" : ""}`}
                      style={{ marginTop: "10px", fontSize: key === "total" && 10, padding: key === "total" && "0 5px" }}
                    >
                      {line.sum.volumetry.time_etalon[key] || ""}
                    </div>
                    <div style={{ marginTop: 10 }} />
                    <div
                      style={{
                        backgroundColor: "#dfdfdf",
                        marginTop: 10,
                        fontSize: "11px",
                      }}
                    >
                      {line.volumetry[key]}
                    </div>
                  </>: ""
                ) : (
                  <div
                    style={{ backgroundColor: key === "total" && "#dfdfdf" }}
                  >
                    {line.volumetry[key] !== undefined ? line.volumetry[key] : 0}
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      case "billing_rate":
        return (
          <div className="colomn" style={{ margin: "15px 0" }}>
            <div
              className="labled-tag no-border"
              style={{ textAlign: "center" }}
            >
              {data.indexOf(line) === 0 && <span>{line["billing_rate"].label}</span>}
              {line.client_id === "%" ? (
                  expanded ? <>
                    <div style={{ display: "flex", gap: 10, marginTop: 10, padding: 0, width: "100%", backgroundColor: "transparent" }}>
                      <div className="vol_label">Ajustement</div>
                      <div>{line.billing_rate && line.billing_rate.fee_adjust}</div>
                    </div>

                    <div style={{ display: "flex", gap: 10, marginTop: 10, padding: 0, width: "100%", backgroundColor: "transparent" }}>
                      <div className="vol_label">∑H+∆%prod</div>
                      <div>{line.billing_rate && parseFloat(line.billing_rate.fee_prod).toFixed(2)}</div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#90ee90",
                        fontSize: "11px",
                        marginLeft: "5px",
                        marginTop: "10px",
                        color: "green"
                      }}
                    >
                      {`+ ${line.billing_rate && line.billing_rate.diff}`}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#dfdfdf",
                        fontSize: "11px",
                        marginLeft: "5px",
                        marginTop: "10px",
                      }}
                    >
                      {line.billing_rate && line.billing_rate.value ? line.billing_rate.value.toFixed(2) : 0}
                    </div>
                  </> : "" 
                ) : (
                  <div style={{ display: "flex", marginLeft: 50 }}>
                    {line.billing_rate && line.billing_rate.value ? line.billing_rate.value : 0}
                  </div>
                )}
            </div>
          </div>
        );
        case "ceiling_compliance_1_6":
          return (
            <div
              className="colomn"
              style={{ margin: "0 auto", width: "100%" }}
            >
              {Object.keys(line.ceiling_compliance_1_6).map((key, index) => (
                <div
                  className="labled-tag no-border"
                  key={index}
                  style={{ textAlign: "center", marginLeft: 5, marginTop: 15 }}
                >
                  {data.indexOf(line) === 0 && <span>{_(key)}</span>}
                  {data.indexOf(line) > 0 ? (
                    <div >
                      {line.ceiling_compliance_1_6 && line.ceiling_compliance_1_6[key]}
                    </div>
                  ) : (
                    <></>
                )}
                </div>
              ))}
            </div>
          );  
      default:
        return <></>;
    }
  };

  const handleTitleClients = (title) => (
    <Fragment>
      {title.client_name.length > 0 ? (
        <div className="clients_panel">
            <div
              className="title_infos"
              style={{
                textAlign: data.indexOf(title) === 0 && "center", zIndex: 2
              }}
            >
              {title.client_name}
            </div>
          
        </div> ) : (
          <div className="clients_panel" style={{ top: 0, height: expanded ? 310 : 120, margin: 0, padding: 0, border: 0, zIndex: 1 }}>
            <div className="expand_arrow">
              <TiArrowSortedUp
                style={{ marginBottom: -5, opacity: expanded ? 1 : .7, }}
                onClick={() => setExpanded(!expanded)}
                size={expanded ? 25 : 15}
              />
              <TiArrowSortedDown
                style={{ marginTop: -5, opacity: expanded ? .7 : 1 }}
                onClick={() => setExpanded(!expanded)}
                size={expanded ? 15 : 25}
              />
            </div>
          </div>
        )
      }
    </Fragment>
  );

  const renderFilterFrame = () => {
    let countFilter = 0;
    filters.forEach((filter) => {
      if (filter && filter.options.values) {
        countFilter++;
      }
    });

    return (
      <div className="filter-frame" style={{ width: "100%", height: "100%", padding: "10px 5px" }}>
        <div className="principal-line" style={{ width: "100%" }}>
          <div className="filter-wrapper">Séléctionné: {selectedCollaborator?.label}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <div className="mini-tabs-frame" style={{marginRight: 350}}>
              {filter.filterTab.map((item) => (
                <div
                  key={item.id}
                  className={
                    item.id === filter.period.id ? "mini-tabs-frame_active" : ""
                  }
                  onClick={() => {
                    setFilter({
                      ...filter,
                      period: item,
                      subPeriod: "ANNUAL" !== item.id ? item.subTab[0] : null,
                    });
                  }}
                >
                  {_(item.label)}
                </div>
              ))}
            </div> */}
            <div className="row align-center medium-12" style={{position: "absolute", top: 0, right: 5}}>
              <div className="medium-12">
                <TTPFilterHorizontalBar
                  button
                  bg="#FFF"
                  subBG="F8F9FA"
                  filter={filter}
                  filters={filters}
                  nbResult={nbrClients}
                  countFilter={countFilter}
                  pageSize={filter.pageSize}
                  updateFilterContent={setFilter}
                  cssClass="medium-12"
                  customAddOn={renderYearSelect()}
                />
              </div>
            </div>
          </div>
        </div>

        {"ANNUAL" !== filter.period.id ? (
          <div className="additional-line">
            {filter.period.subTab.map((tab) => {
              return (
                <div
                  key={tab.id}
                  className={`tag ${filter.subPeriod?.id === tab.id ? "active" : ""}`}
                  onClick={() => setFilter({ ...filter, subPeriod: tab })}
                >
                  {tab.label}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };
  
  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
          zIndex: 2
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => setYear(item)}
                  style={{ zIndex: 4 }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      { hasAccess === "1" ? (
        <div className="page-content" style={{ background: "none" }}>
        <div className="ttp-tab-container">
          <div
            className="content-body large-content"
            style={{ padding: "0" }}
          >
            {renderFilterFrame()}
            {loading ? (
              <div className="ripple_loading">
                <img src={"/img/Ripple-1s-150px.svg"} alt="" />
              </div>
            ) : data.length > 0 && filter.collaborator ? (
              <div style={{position: "relative" }}>
                <div className="prev_arrow" onClick={scrollLeft}>
                  <IconArrowRight fill="#29394d" size="15" />
                </div>
                <div ref={scrollRef} style={{ overflowX: "hidden", marginTop: 10 }}>
                  <div style={{ width: "226%", overflowX: "auto" }}>
                    <CustumedMatrix
                      columns={columnsClientsInformations}
                      items={data}
                      handleCellule={handleColumns}
                      handleTitle={(title, index) => handleTitleClients(title, index)}
                      headerTagBG={"#FFFFFF"}
                    />
                  </div>
                </div>
                <div className="next_arrow" onClick={scrollRight}>
                  <IconArrowRight fill="#29394d" size="15" />
                </div>
                {renderPaginate()}
              </div>
            ) : (
              <div className="no_data">
                <div className="title">{_("noDataFound")}</div>
                <div className="subTitle">{_("noReportingsFound")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      ): ""}
    </Fragment>
  );
};

export default PrestationSuivi;
