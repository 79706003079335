import React, { useState } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import moment from "moment";
import classNames from "classnames";
import {
  IconPlus,
  IconChevronDown,
  IconTrash,
  IconPencil,
} from "../../common/Icons";
import { deleteCollaboratorPlanner, removeTeamsMeetingEvent } from "../../../api";
import { toast } from "react-toastify";
const Item = ({
  events,
  setEvents,
  backupEvents,
  token,
  organizationId,
  setOpenAddEvent,
  setSelectedDateEvent,
  renderDurationDisplay,
  formatDuration,
  timeSheetByDays,
  timeSheetNFByDays,
  holidays,
  durationsByDay,
  workingHours,
  dayMapping,
  day,
  dayIndex,
  setSelectedCategory,
  selectedDateEvent,
  selectedDurationHours,
  setSelectedDurationHours,
  setSelectedDurationTravelHours,
  setSelectedDuration,
  selectedDurationMin,
  setSelectedDurationMin,
  setSelectedDurationTravelMin,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  setSelectedDescription,
  setSelectedTypeEvent,
  setSelectedTitleEvent,
  setSelectedClient,
  setSelectedEventId,
  weekIndex,
  collapsedWeeks,
  toggleCollapseWeek,
  isMonthVue,
  selectedMeetingInfo,
  setSelectedMeetingInfo,
  isBackupEventsVisible
}) => {
  const onClickEvent = (event) => {
    setSelectedClient({ value: event.client_id, label: event.client_name });
    setSelectedCategory({
      value: event.category_id,
      label: event.category_name,
    });
    setSelectedDateEvent(moment(event.date.date).toDate());
    setSelectedDurationHours(Math.floor(event.duration / 60));
    setSelectedDurationMin(event.duration % 60);
    setSelectedDurationTravelHours(Math.floor(event.durationTravel / 60));
    setSelectedDurationTravelMin(event.durationTravel % 60);
    setSelectedStatus(event.status);
    setSelectedPlaceRDV(event.meetingPlace);
    setSelectedDescription(event.description);
    setSelectedTypeEvent(event.type);
    setSelectedTitleEvent(event.title);
    setSelectedEventId(event.id);
    setSelectedDuration(event.duration);
    setSelectedMeetingInfo({
      id: event.meetingId,
      url: event.meetingUrl
    });
    setOpenAddEvent(true);
  };
  const deletePlanner = async (event) => {
    if (event && token && organizationId) {
      try {
        // removing event from Microsoft Outlook Calendar
        const msApiAccessToken = localStorage.getItem('ms_api_access_token');

        if (msApiAccessToken) {
          // remove event from Outlook Planner
          await removeTeamsMeetingEvent(msApiAccessToken, event.meetingId);
        }

        // remove planner from database
        const response = await deleteCollaboratorPlanner(
          event.id,
          organizationId,
          token
        );

        if (response.data.result === "OK") {
          const updatedEvents = events.filter((e) => e.id !== event.id);
          setEvents(updatedEvents);
          toast.success(_("saveSuccess"));
        } else {
          toast.error(_("savingError"), { autoClose: true });
          // toast.error("Configurer votre compte Outlook pour supprimer le planner aussi de votre compte Outlook.");
        }
      } catch (e) {
        toast.error(_("savingError"), { autoClose: true });
        return null;
      }
    }
  };

  const convertDecimalTime = (decimalTime) => {
    let hours = Math.floor(decimalTime);
    let decimalPart = decimalTime - hours;

    let minutes = Math.round(decimalPart * 60);

    return hours + "h " + minutes;
  };
  const cardEvent = (events, isBackup) => {
    return (
      <div>
        {events
          .filter(
            (event) =>
              event.date &&
              moment(moment(event.date.date, "Y-M-D").toDate()).isSame(
                day,
                "day"
              )
          )
          .map((filteredEvent, eventIndex) => (
            <div
              key={eventIndex}
              className={`event ${filteredEvent.status}  ${
                isBackup && "backup_item"
              }`}
            >
              <div className="event_type">
                <div className="line_content">
                  {filteredEvent.type !== "meeting" && (
                    <div className="circle">
                      <div
                        className={classNames(
                          "circle",
                          filteredEvent.status === "done" ||
                            filteredEvent.status === "Terminé"
                            ? "tag-success"
                            : filteredEvent.status === "inprogress" ||
                              filteredEvent.status === "En cours"
                            ? "tag-warning"
                            : "tag-danger"
                        )}
                      ></div>
                    </div>
                  )}
                  <div className={`type`}>
                    {filteredEvent.type !== "meeting"
                      ? filteredEvent.type
                      : filteredEvent.type +
                        " : " +
                        _(filteredEvent.meetingPlace)}
                  </div>
                </div>
                <div className="line_content">
                  <div
                    className={`type duration ${!isBackup && "item_duration"}`}
                  >
                    {formatDuration(filteredEvent.duration)}
                  </div>
                  {!isBackup && (
                    <div className="event_actions line_content">
                      <div onClick={() => onClickEvent(filteredEvent)}>
                        <IconPencil size={14} />
                      </div>
                      <div onClick={() => deletePlanner(filteredEvent)}>
                        <IconTrash />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="client_name">
                {filteredEvent.type !== "management"
                  ? filteredEvent.client_name
                  : filteredEvent.title}
              </div>
              {filteredEvent.type !== "meeting" && (
                <div className="line_space_content" style={{ width: "100%" }}>
                  <div className="category_name">
                    {filteredEvent.category_name}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="calendar_columns" key={dayIndex}>
      <div className={classNames("day_label")}>{day.format("dddd")}</div>
      <div className={classNames("day_label")}>{day.format("DD")}</div>
      <div
        className={classNames(
          "day_content",
          day.isSame(moment(), "day") ? "selected_day" : ""
        )}
      >
        <div className="day_header line_space_content">
          <div
            onClick={() => {
              setOpenAddEvent(true);
              setSelectedDateEvent(day.format("YYYY/MM/DD"));
              setSelectedEventId(null);
            }}
          >
            <IconPlus size={13} />
          </div>

          <div className="realised">
            Realisé:
            {timeSheetByDays.filter(
              (task) =>
                task.date &&
                moment(task.date.replace(" ", "T") + "Z").isSame(day, "day")
            ).length > 0 ? (
              timeSheetByDays
                .filter((filteredEvent) =>
                  moment(filteredEvent.date.replace(" ", "T") + "Z").isSame(
                    day,
                    "day"
                  )
                )
                .map((filteredEvent, eventIndex) => (
                  <>
                    {renderDurationDisplay(
                      filteredEvent.total_task_duration * 60
                    )}
                  </>
                ))
            ) : (
              <>{renderDurationDisplay(0)}</>
            )}
          </div>

          <div
            className="disponibility"
            style={{
              background:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(255, 226, 221)",
              color:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(93, 23, 21)",
            }}
          >
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length > 0
              ? holidays
                  .filter((event) => moment(event.date).isSame(day, "day"))
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{
                        background: "rgb(255, 226, 221)",
                        backgroundColor: holiday.key,
                        color: "rgb(93, 23, 21)",
                      }}
                    >
                      {holiday.label.slice(0, 14)}
                    </div>
                  ))
              : durationsByDay &&
                durationsByDay.filter((duration) =>
                  moment(duration.date).isSame(day, "day")
                ).length > 0
              ? durationsByDay
                  .filter((duration) =>
                    moment(duration.date).isSame(day, "day")
                  )
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{ background: holiday.key }}
                    >
                      {formatDuration(holiday.duration)}
                    </div>
                  ))
              : formatDuration(0)}
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length === 0 && (
              <>
                {" "}
                sur{" "}
                {convertDecimalTime(
                  workingHours[dayMapping[moment(day).format("ddd")]]
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={classNames(
            "events",
            collapsedWeeks[weekIndex] ? "collapsed" : ""
          )}
        >
          {cardEvent(events, false)}
          {isBackupEventsVisible && cardEvent(backupEvents, true)}
        </div>
        {isMonthVue &&
          events &&
          events.filter(
            (event) => event.date && moment(event.date.date).isSame(day, "day")
          ).length > 2 && (
            <div
              className="day_footer"
              onClick={() => toggleCollapseWeek(weekIndex)}
            >
              {collapsedWeeks[weekIndex] ? (
                <div>
                  +{" "}
                  {events.filter((event) =>
                    moment(event.date.date).isSame(day, "day")
                  ).length - 2}{" "}
                  cartes <IconChevronDown size={10} fill="#29394D" />
                </div>
              ) : (
                <div className="action">
                  Reduire <IconChevronDown size={10} fill="#29394D" />
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default Item;
