import React from "react";

import { Carousel } from "primereact/carousel";
import "./TTPTab.local.scss";
import _ from "../../../i18n";

const TTPTab2 = ({ tabs, activeTab, selectTab, width = "100%" }) => {
  return (
    <div style={{ margin: "0 auto", width: width }}>
      <Carousel
        value={tabs}
        numVisible={5}
        numScroll={1}
        responsiveOptions={[]}
        itemTemplate={(tab) => {
          return (
            <div
              key={tab.id}
              href="#"
              className={`carousel-item ${
                activeTab === tab.id ? "active" : ""
              }`}
              onClick={(ev) => selectTab(tab)}
              style={{
                width: 250,
              }}
            >
              {_(tab.label)}
            </div>
          );
        }}
      />
    </div>
  );
};

export default TTPTab2;
