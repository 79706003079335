import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getClientsSuggestions,
  getCategoriesSuggestions,
  getClientProgressionBudget,
  getCollaboratorsPlanner,
  saveCollaboratorsPlanner,
  sendGenericEmail,
  REDIRECT_URI,
  CLIENT_ID,
  exchangeCodeForTokens,
  TENANT_ID,
  createTeamsMeetingEvent,
  refreshAccessToken,
  updateTeamsMeetingEvent,
  createTokenLifetimePolicy,
  getCollaboratorEmail,
} from "../../../api";
import moment from "moment";
import "moment/locale/fr";
import "./Planner.local.scss";
import CalendarPage from "./Calendar.js";
import ListView from "./ListView.js";
import SideBarPlanner from "./SideBarPlanner.js";
import _ from "../../../i18n";
import { IconPlus, IconChevronDown, IconEye } from "../../common/Icons";
import { MICROSOFT_GRAPH_API_CLIENT_ID, MICROSOFT_GRAPH_API_REDIRECT_URI, MICROSOFT_GRAPH_API_TENANT_ID, TTP_UA_URL } from "../../../config";
import {
  renderDurationDisplay,
  formatDuration
} from "../../../utils";
import { Button } from "antd";
import { useSelector } from "react-redux";
moment.locale("fr");
const formatDate = (date, dashSeparation = false) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  if (dashSeparation) {
    return `${year}-${month}-${day}`;
  }
  return `${year}/${month}/${day}`;
};

const formatDateForTeams = (date, withHours = true) => {
  const localDate = date.toLocaleString();
  const dateParts = localDate.split(", ");

  const [day, month, year] = dateParts[0].split("/");

  if (withHours) {
    return `${year}-${month}-${day}T${dateParts[1]}`;
  } else {
    return `${year}-${month}-${day}`;
  }
}

const today = formatDate(new Date());
const Planner = ({
  token,
  navCommunity,
  organizationId,
  collaborator,
  lng,
  year,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [currentDate, setCurrentDate] = useState(moment());
  const [clientOptions, setClientOptions] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);
  const [taskCategoriesOptions, setTaskCategoriesOptions] = useState([]);
  const [collapsedWeeks, setCollapsedWeeks] = useState({});
  const [selectedPeriodTypeId, setSelectedPeriodTypeId] = useState(1);
  const [selectedCalendarTypeId, setSelectedCalendarTypeId] = useState(1);
  const [openAddEvent, setOpenAddEvent] = useState(false);
  const [selectedTypeEvent, setSelectedTypeEvent] = useState(null);
  const [selectedTitleEvent, setSelectedTitleEvent] = useState(null);
  const [selectedDateEvent, setSelectedDateEvent] = useState(new Date());
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("A faire");
  const [selectedDescription, setSelecteddescription] = useState(null);
  const [selectedDurationHours, setSelectedDurationHours] = useState(0);
  const [selectedDurationTravelHours, setSelectedDurationTravelHours] =
    useState(0);
  const [selectedPlaceRDV, setSelectedPlaceRDV] = useState(null);
  const [selectedDurationMin, setSelectedDurationMin] = useState(0);
  const [selectedDurationTravelMin, setSelectedDurationTravelMin] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [selectedMeetingInfo, setSelectedMeetingInfo] = useState(null);
  const [isBackupEventsVisible, setIsBackupEventsVisible] = useState(false);
  const [typesEventDivIsVisible, setTypesEventDivIsVisible] = useState(null);
  const [clientsDivIsVisible, setClientsDivIsVisible] = useState(null);
  const [categoriesDivIsVisible, setCategoriesDivIsVisible] = useState(null);
  const [statusDivIsVisible, setStatusDivIsVisible] = useState(null);
  const [childrens, setChildrens] = useState([]);
  const [timeSheetByDays, setTimeSheetByDays] = useState([]);
  const [timeSheetNFByDays, setTimeSheetNFByDays] = useState([]);
  const [holidaysInWeek, setHolidaysInWeek] = useState([]);
  const [totalTimeByWeek, setTotalTimeByWeek] = useState([]);
  const [totalRealTimeByWeek, setTotalRealTimeByWeek] = useState([]);
  const [totalRealTimeNFByWeek, setTotalRealTimeNFByWeek] = useState([]);
  const [overtimeByWeek, setOvertimeByWeek] = useState([]);
  const [events, setEvents] = useState([]);
  const [backupEvents, setBackupEvents] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [durationsByDay, setDurationsByDay] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [currentWeek, setCurrentWeek] = useState(moment());
  const [weekAll, setWeekAll] = useState([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [selectedShemaType, setSelectedShemaType] = useState(1);
  const [plannerConfiguration, setPlannerConfiguration] = useState(null);
  const [selectedCollaboratorEmail, setSelectedCollaboratorEmail] = useState(null);

  const calendarTabs = [
    {
      id: 1,
      label: "calandrier",
      title: "calendrier",
    },
    {
      id: 2,
      label: "recapulatif",
      title: "recapulatif",
    },
  ];
  const schemaTypeTabs = [
    {
      id: 1,
      label: "category",
      title: "category",
    },
    {
      id: 2,
      label: "clients",
      title: "clients",
    },
  ];
  const periodTabs = [
    {
      id: 1,
      label: "HEBDOMADAIRE",
      title: "HEBDOMADAIRE",
    },
    {
      id: 2,
      label: "MENSUEL",
      title: "MENSUEL",
    },
  ];
  const statusOptions = [
    { label: "A faire", value: "todo" },
    { label: "En cours", value: "inprogress" },
    { label: "Terminé", value: "done" },
  ];
  const dayMapping = {
    "lun.": "Mon",
    "mar.": "Tue",
    "mer.": "Wed",
    "jeu.": "Thu",
    "ven.": "Fri",
    "sam.": "Sat",
    "dim.": "Sun",
  };

  const [startDate, setStartDate] = useState(new Date(2024, 1, 1));
  const [endDate, setEndDate] = useState(new Date(2024, 12, 7));
  const [orderBy, setOrderBy] = useState("category");
  const [transformedData, setTransformedData] = useState({});
  const [transformedDataClients, setTransformedDataClients] = useState({});
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [budgetProgression, setBudgetProgression] = useState({});
  const [responsables, setResponsables] = useState(null);
  const [borderedcolumns, setBorderedColumns] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  let eventsPlanner = useQuery(
    ["getPlannersList", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getCollaboratorsPlanner(
            organizationId,
            collaborator.id,
            year,
            token
          );
          setEvents(response.data.data.events);
          setBackupEvents(response.data.data.backupEvents);
          setTimeSheetByDays(response.data.data.tasksByDay);
          setTimeSheetNFByDays(response.data.data.tasksNFByDay);
          // transformDataByWeek(
          //   response.data.data.events,
          //   startDate,
          //   endDate,
          //   orderBy
          // );
          setHolidays(response.data.data.holidays);
          setAvailabilities(response.data.data.availabilities);
          setDurationsByDay(response.data.data.durationsByDay);
          setWorkingHours(response.data.data.workingHours);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let clientProgressionBudgetQuery = useQuery(
    ["getClientsList", token, organizationId, selectedClient, selectedCategory],
    async () => {
      if (token && selectedClient) {
        let columns = [];
        let entityColumns = [];
        try {
          const response = await getClientProgressionBudget(
            selectedClient.value,
            organizationId,
            token,
            { year: 2024, category: { id: selectedCategory.value } }
          );

          setBudgetProgression(response.data.data.progression);
          setResponsables(response.data.data.responsables);
          // setVolumetry(response.data.data.volumetry);
          // setGlobal(response.data.data.global);

          Object.keys(response.data.data.responsables).map((element) => {
            response.data.data.responsables[element].roles
              .filter((role) => role !== "other")
              .map((role) => {
                columns.push({
                  id: role,
                  label: _(role),
                  isEditable: true,
                  widthPercentage: 15,
                });
              });
          });
          setBorderedColumns(columns);
        } catch (e) {
          // setBorderedColumns({});
          // setBudgetProgression({});
          // // setResponsables(null);
          // setVolumetry([]);
          // setGlobal(null);
          return null;
        }
      }
    }
  );

  let taskCateoriesdQuery = useQuery(
    ["getCategoriesSuggestions", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getCategoriesSuggestions(
            organizationId,
            token
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label.fr,
              };
            });
          setTaskCategoriesOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let clientsdQuery = useQuery(
    ["getClients", token, organizationId],
    async () => {
      if (token && organizationId) {
        try {
          const response = await getClientsSuggestions(
            token,
            [],
            organizationId,
            "isEntity",
            year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setAllClientsData(response.data.data);
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  useEffect(() => {
    // get Microsoft Graph Api tokens from local Storage
    const msAccessToken = localStorage.getItem('ms_api_access_token');
    const msRefreshToken = localStorage.getItem('ms_api_refresh_token');
    const msExpiresAt = localStorage.getItem('ms_api_expires_at');

    if (msAccessToken && msRefreshToken && msExpiresAt) {
      // if token is expired and refresh token is valid we update tokens
      (async function expandTokensDuration() {
        await createTokenLifetimePolicy(msAccessToken);
      })();

      if (Date.now() > new Date(msExpiresAt)) {
        (async function getTokens () {
          const newTokens = await refreshAccessToken(msRefreshToken);
          if (newTokens) {
            const accessToken = newTokens.access_token;
            const refreshToken = newTokens.refresh_token;
            const expirationDate = new Date((new Date()).getTime() + newTokens.expires_in * 1000).toISOString();
            localStorage.setItem('ms_api_access_token', accessToken);
            localStorage.setItem('ms_api_refresh_token', refreshToken);
            localStorage.setItem('ms_api_expires_at', expirationDate)
            setPlannerConfiguration({
              accessToken: accessToken,
              refreshToken: refreshToken,
              expirationDate: expirationDate
            })
          }
        })();
      } else {
        setPlannerConfiguration({
          accessToken: msAccessToken,
          refreshToken: msRefreshToken,
          expirationDate: msExpiresAt
        })
      }
    }

    // get Collaborator E-mail
    (async function getSelectedCollaboratorEmail() {
      const response = await getCollaboratorEmail({token, collaboratorId: collaborator.id, organizationId});
      if (response.data?.data && response.data.data.length > 0) {
        setSelectedCollaboratorEmail(response.data.data[0].email);
      }
    })();
  }, []);


  const composeEventDetailsForTeams = () => {
    let startDate = new Date(selectedDateEvent);
    let endDate = new Date(selectedDateEvent);
    const minutesToAdd = parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin);
    let title = "";
    if (selectedTypeEvent === 'meeting') {
      endDate.setMinutes(endDate.getMinutes() + minutesToAdd);
      title = `Rendez-vous avec ${selectedClient.label}`
    } else {
      startDate.setHours(0, 0, 0, 0); // Set the Date to midnight because this event lasts the entire day (it's required)
      endDate = new Date(startDate);
      endDate.setMinutes(selectedDateEvent.getMinutes() + 1440); // 1440 minutes = 1 day
      endDate.setHours(0, 0, 0, 0); // Set the Date to midnight because this event lasts the entire day (it's required)

      if (selectedTypeEvent === 'task') {
        title = `[Tâche] ${selectedClient.label} - ${selectedCategory.label}`
      } else {
        title = `[Gestion] ${selectedTitleEvent}`
      }
    }

    const eventDetails = {
      subject: title,
      start: {
        dateTime: formatDateForTeams(startDate),
        timeZone: collaborator.zoneId === 1 ? 'Europe/Brussels' : 'Africa/Casablanca',
      },
      end: {
        dateTime: formatDateForTeams(endDate),
        timeZone: collaborator.zoneId === 1 ? 'Europe/Brussels' : 'Africa/Casablanca',
      },
      location: {
        displayName: "Microsoft Teams",
      },
    };

    if (selectedTypeEvent === 'meeting') {
      eventDetails.isOnlineMeeting = true;
      eventDetails.onlineMeetingProvider = "teamsForBusiness"; // Specify Teams as the meeting provider
      eventDetails.allowNewTimeProposals = true; // Allow attendees to propose new meeting times
      eventDetails.responseRequested = true; // Request responses from attendees

      // add collaborator e-mail
      if (selectedCollaboratorEmail && selectedCollaboratorEmail !== user.mainEmail) {
        // this one for production
        // eventDetails.attendees = [{
        //   emailAddress: {
        //     address: selectedCollaboratorEmail,
        //     name: collaborator.name,
        //   },
        //   type: "required"
        // }];

        // this one for testing
        eventDetails.attendees = [{
          emailAddress: {
            address: "tamtam@degandpartners.com",
            name: "Tamtam Pro User"
          },
          type: "required"
        }]
      }
      // TODO: add client e-mail also
    } else if (selectedTypeEvent === 'task' || selectedTypeEvent === 'management') {
      eventDetails.isReminderOn = true;
      eventDetails.isAllDay = true;
    }

    return eventDetails;
  }

  const saveCollaboratorPlanner = async () => {
    setIsSaving(true);

    let meetingId = null;
    let meetingUrl = null;

    if (plannerConfiguration) {
      const eventDetails = composeEventDetailsForTeams();

      let eventData = null;
      if (selectedEventId) {
        eventData = await updateTeamsMeetingEvent(plannerConfiguration.accessToken, selectedMeetingInfo.id, eventDetails);
      } else {
        eventData = await createTeamsMeetingEvent(plannerConfiguration.accessToken, eventDetails);
      }
      meetingId = eventData.id;
      meetingUrl = eventData?.onlineMeeting?.joinUrl;
      setSelectedMeetingInfo({
        id: meetingId,
        url: meetingUrl,
      });
    }
    try {
      const response = await saveCollaboratorsPlanner(
        selectedEventId,
        organizationId,
        selectedTypeEvent,
        selectedTitleEvent,
        selectedPlaceRDV,
        collaborator.id,
        selectedClient ? selectedClient.value : null,
        selectedCategory ? selectedCategory.value : null,
        selectedStatus,
        selectedDateEvent?.toISOString(),
        selectedDescription,
        parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin),
        parseInt(selectedDurationTravelHours) * 60 +
          parseInt(selectedDurationTravelMin),
        year,
        token,
        meetingId,
        meetingUrl
      );
      if (response.data.result === "OK") {
        let updatedEvent = response.data.data;
        let updatedEvents;
        if (selectedEventId) {
          updatedEvents = events.map((event) =>
            event.id === selectedEventId ? updatedEvent : event
          );
        } else {
          updatedEvents = [...events, updatedEvent];
        }

        setEvents(updatedEvents);
        transformDataByWeek(updatedEvents, startDate, endDate, orderBy);
        const updatedDuration =
          parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin);
        const updatedDurationsByDay = durationsByDay.map((day) => {
          if (
            moment(day.date).format("YYYY-MM-DD") ===
            moment(selectedDateEvent).format("YYYY-MM-DD")
          ) {
            return {
              ...day,
              duration: day.duration - selectedDuration + updatedDuration, // Add the event duration to the existing one
            };
          }
          return day;
        });
        // If the date doesn't exist, add a new entry
        const isDatePresent = updatedDurationsByDay.some(
          (day) =>
            moment(day.date).format("YYYY-MM-DD") ===
            moment(selectedDateEvent).format("YYYY-MM-DD")
        );
        if (!isDatePresent) {
          updatedDurationsByDay.push({
            date: selectedDateEvent,
            duration:
              parseInt(selectedDurationHours) * 60 +
              parseInt(selectedDurationMin),
          });
        }
        setDurationsByDay(updatedDurationsByDay);
        toast.success(_("successfully_added"));
        let responsables = [
          // {
          //   id: 528409,
          //   name: "Yousra Berradi",
          //   email: "yousra.berradi@oam.ma",
          // },
          // {
          //   id: 458410,
          //   name: "Yassine El Bekkali",
          //   email: "yassine.el.bekkali21@gmail.com",
          // },
          // {
          //   id: collaboratorApiId,
          //   name: collaboratorName,
          //   email: collaboratorEmail,
          // },
          {
            id: 476905,
            name: "Fatima Ezzahra Missiameri",
            email: "ttp.fem@gmail.com"
          }
        ];

        sendEmailToResponsable(responsables, null, null);
        // }
      } else {
        toast.error(_("savingError"), { autoClose: true });
      }
    } catch (e) {
      toast.error(_("savingError"), { autoClose: true });
      return null;
    } finally {
      setIsSaving(false)
    }
    setOpenAddEvent(false);
    setSelectedCategory(null);
    setSelectedClient(null);
    setSelectedDateEvent(null);
    setSelectedTypeEvent(null);
    setSelectedTitleEvent(null);
    setSelectedDurationHours(0);
    setSelectedDurationTravelHours(0);
    setSelectedDurationMin(0);
    setSelectedDurationTravelMin(0);
    setSelectedDuration(0);
    setSelectedMeetingInfo(null);
    // refreshEvents();
  };

  const sendEmailToResponsable = async (responsables, client, status) => {
    const recipients = {};
    const dynamicFields = {};
    const promises = responsables.map(async (responsable) => {
      try {
        recipients[responsable.apiId ?? "470189"] = responsable?.email ?? "";
        dynamicFields["{{RECIPIENT}}"] = collaborator.name;

        dynamicFields[
          "{{OBJET}}"
        ] = `Une nouvelle tâche vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent}.
        `;
        if (selectedTypeEvent === "task") {
          if (selectedEventId) {
            dynamicFields[
              "{{OBJET}}"
            ] = `Une nouvelle tâche vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent}.
        `;
          } else {
            dynamicFields[
              "{{OBJET}}"
            ] = `Une modification sur vos tâche a été faite pour le client ${selectedClient.label} à la date du ${selectedDateEvent}.
       `;
          }
        } else {
          dynamicFields[
            "{{OBJET}}"
          ] = `Un nouveau rendez-vous vous a été assignée pour le client ${selectedClient.label} à la date du ${selectedDateEvent} .
       `;
        }
        dynamicFields["{{ORGANIZATION.NAME}}"] = navCommunity.name;
        dynamicFields["FIDUCIARY_LOGO"] = navCommunity.avatarUrl;
        dynamicFields["{{NOTIF_URL}}"] = `${TTP_UA_URL}/user-space`;
      } catch (error) {
        return null;
      }
    });
    await Promise.all(promises);

    sendGenericEmail(
      token,
      recipients,
      "PLANNER_NOTIFICATION",
      lng,
      dynamicFields,
      organizationId
    );
  };

  useEffect(() => {
    if (clientOptions.length === 0) {
      clientsdQuery.refetch();
    }
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const days = [];
    const nextWeekDays = [];

    for (let i = 0; i <= 4; i++) {
      const day = startOfWeek.clone().add(i, "days");
      const nextWeekDay = startOfWeek.clone().add(i + 7, "days");

      days.push(day);
      nextWeekDays.push(nextWeekDay);
    }
    setWeekAll(days);
    setCurrentWeek(currentWeek);

    generateWeeks();
  }, [currentMonth, clientOptions]);

  const calculateTotalHoursPerWeek = (hoursPerDay, holidaysInWeek) => {
    let totalMinutes = 0;
    console.log(
      "******************hoursPerDay, holidaysInWeek=============",
      hoursPerDay,
      holidaysInWeek
    );
    Object.keys(hoursPerDay).forEach((day) => {
      const isHoliday = holidaysInWeek.some((holiday) => {
        const holidayDay = moment(holiday.date).locale("en").format("ddd");
        return holidayDay === day;
      });

      if (!isHoliday) {
        console.log("*******************", isHoliday);
        const time = hoursPerDay[day];
        const hours = Math.floor(time);
        const minutes = Math.round((time % 1) * 60);

        totalMinutes += hours * 60 + minutes;
      }
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return { totalHours, remainingMinutes };
  };

  const getHolidaysInWeek = (startDate, endDate, holidays) => {
    // console.log("******************======getHolidaysInWeek======", holidays);
    // console.log(
    //   "******************======getHolidaysInWeek====startDate, endDate==",
    //   startDate,
    //   endDate
    // );
    return holidays.filter((holiday) => {
      const holidayDate = moment(holiday.date);
      console.log();
      return holidayDate.isBetween(startDate, endDate, null, "[]");
    });
  };

  const transformDataByWeek = (events, startDate, endDate) => {
    let firstDay, lastDay;

    if (selectedPeriodTypeId === 2) {
      firstDay = moment(weeks[0][0]._d);
      lastDay = moment(
        weeks[weeks.length - 1][weeks[weeks.length - 1].length - 1]._d
      );
    } else if (selectedPeriodTypeId === 1) {
      firstDay = moment(weekAll[0]._d);
      lastDay = moment(weekAll[weekAll.length - 1]._d);
    }
    setStartDate(firstDay);
    setEndDate(lastDay);
    const weekEvents = events.filter((event) => {
      const eventDate = moment(event.date.date);
      return (
        eventDate.isBetween(firstDay, lastDay, null, "[]") &&
        event.type === "task"
      );
    });
    const groupedDataByCategory = weekEvents.reduce((acc, event) => {
      const categoryKey = event.category_id;
      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          id: event.category_id,
          label: event.category_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
          childrens: [],
        };
      }
      const clientKey = event.client_id;
      let client = acc[categoryKey].childrens.find((c) => c.id === clientKey);

      if (!client) {
        client = {
          id: clientKey,
          label: event.client_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
        };
        acc[categoryKey].childrens.push(client);
      }

      client.estimated += event.duration ? parseInt(event.duration) : 0;
      client.realized += event.task_duration ? event.task_duration * 60 : 0;
      client.ecart = client.estimated - client.realized;

      acc[categoryKey].estimated += event.duration
        ? parseInt(event.duration)
        : 0;
      acc[categoryKey].realized += event.task_duration
        ? event.task_duration * 60
        : 0;
      acc[categoryKey].ecart =
        acc[categoryKey].estimated - acc[categoryKey].realized;

      return acc;
    }, {});

    const groupedDataByClient = weekEvents.reduce((acc, event) => {
      const clientKey = event.client_id;
      if (!acc[clientKey]) {
        acc[clientKey] = {
          id: event.client_id,
          label: event.client_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
          childrens: [],
        };
      }

      const categoryKey = event.category_id;
      let category = acc[clientKey].childrens.find((c) => c.id === categoryKey);

      if (!category) {
        category = {
          id: categoryKey,
          label: event.category_name,
          estimated: 0,
          realized: 0,
          ecart: 0,
        };
        acc[clientKey].childrens.push(category);
      }

      category.estimated += event.duration ? parseInt(event.duration) : 0;
      category.realized += event.task_duration ? event.task_duration * 60 : 0;
      category.ecart = category.estimated - category.realized;

      acc[clientKey].estimated += event.duration ? parseInt(event.duration) : 0;
      acc[clientKey].realized += event.task_duration
        ? event.task_duration * 60
        : 0;
      acc[clientKey].ecart = acc[clientKey].estimated - acc[clientKey].realized;

      return acc;
    }, {});

    const categoriesList = Object.values(groupedDataByCategory);
    const clientsList = Object.values(groupedDataByClient);
    setTransformedDataClients(clientsList);
    setTransformedData(categoriesList);

    return clientsList;
  };

  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}`;
  };

  const generateWeeks = () => {
    const startOfMonth = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");
    const weeks = [];
    const collapsedState = {};

    let weekStart = startOfMonth.clone();
    if (startOfMonth.day() === 6) {
      weekStart.add(2, "days");
    } else if (startOfMonth.day() === 0) {
      weekStart.add(1, "days");
    } else {
      weekStart = startOfMonth.clone().startOf("week").add(0, "days");
    }

    while (weekStart.isBefore(endOfMonth.clone().endOf("week"))) {
      const days = [];
      let day = weekStart.clone();

      for (let i = 0; i < 5; i++) {
        days.push(day.clone());
        day.add(1, "day");
      }

      weeks.push(days);
      weekStart.add(7, "days");
      collapsedState[weeks.length - 1] = true;
    }

    setWeeks(weeks);
    setCollapsedWeeks(collapsedState);
  };

  const renderCustomAddOn = (selectedId, items, type) => {
    return (
      <div className="filter_custom">
        <div className={"mini-tabs-frame"}>
          {items.map((item) => (
            <div
              key={item.id}
              className={selectedId === item.id ? "mini-tabs-frame_active" : ""}
              onClick={() =>
                type === "calendar"
                  ? handleCalendarTypeChange(item.id) // Reset to current week
                  : handlesetSelectedPeriodTypeIdChange(item.id)
              }
            >
              {_(item.label)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handlePreviousWeek = () => {
    setWeekAll((prevWeekAll) => {
      const startOfWeek = prevWeekAll[0].clone().startOf("week");
      const days = [];
      for (let i = 0; i <= 4; i++) {
        const day = startOfWeek.clone().add(i - 7, "days");
        days.push(day);
      }
      return days;
    });
  };

  const handleNextWeek = () => {
    setWeekAll((prevWeekAll) => {
      const startOfWeek = prevWeekAll[0].clone().startOf("week");
      const days = [];
      for (let i = 0; i <= 4; i++) {
        const day = startOfWeek.clone().add(i + 7, "days");
        days.push(day);
      }
      return days;
    });
  };
  const calculateTotalDurationForWeek = (
    durationsByDay,
    startDate,
    endDate
  ) => {
    let totalMinutes = 0;

    durationsByDay.forEach((day) => {
      const dayDate = moment(day.date);
      if (dayDate.isBetween(startDate, endDate, null, "[]")) {
        totalMinutes += day.duration;
      }
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return { totalHours, remainingMinutes };
  };

  const calculateOvertime = (totalTimeByWeek, totalRealTimeByWeek) => {
    const totalTimeInMinutes =
      totalTimeByWeek.totalHours * 60 + totalTimeByWeek.remainingMinutes;
    const totalRealTimeInMinutes =
      totalRealTimeByWeek.totalHours * 60 +
      totalRealTimeByWeek.remainingMinutes;

    if (totalRealTimeInMinutes > totalTimeInMinutes) {
      const overtimeInMinutes = totalRealTimeInMinutes - totalTimeInMinutes;

      const overtimeHours = Math.floor(overtimeInMinutes / 60);
      const overtimeMinutes = overtimeInMinutes % 60;

      return { overtimeHours, overtimeMinutes };
    } else {
      return { overtimeHours: 0, overtimeMinutes: 0 };
    }
  };

  useEffect(() => {
    if (selectedCalendarTypeId === 2) {
      transformDataByWeek(events, startDate, endDate);
      const holidaysInWeek = getHolidaysInWeek(startDate, endDate, holidays);
      const totalTime = calculateTotalHoursPerWeek(
        workingHours,
        holidaysInWeek
      );
      const totalRealTime = calculateTotalDurationForWeek(
        durationsByDay,
        startDate,
        endDate
      );
      const totalRealNFTime = calculateTotalDurationForWeek(
        timeSheetNFByDays,
        startDate,
        endDate
      );
      const overtime = calculateOvertime(totalTimeByWeek, totalRealTimeByWeek);
      setOvertimeByWeek(overtime);
      setHolidaysInWeek(holidaysInWeek);
      setTotalTimeByWeek(totalTime);
      setTotalRealTimeByWeek(totalRealTime);
      setTotalRealTimeNFByWeek(totalRealNFTime);
    }
  }, [weekAll, startDate, endDate]);

  const handleCalendarTypeChange = (type) => {
    if (type === 2) {
      handlesetSelectedPeriodTypeIdChange(1);
    }
    setSelectedCalendarTypeId(type);
    setCurrentWeek(moment().startOf("week"));
    setCurrentMonth(moment());
    transformDataByWeek(events, startDate, endDate, orderBy);
  };

  useEffect(() => {
    if (selectedCalendarTypeId === 2 && selectedPeriodTypeId) {
      transformDataByWeek(events, startDate, endDate, orderBy);
    }
  }, [selectedPeriodTypeId]);

  const handlesetSelectedPeriodTypeIdChange = (type) => {
    if (selectedCalendarTypeId !== 2) {
      setSelectedPeriodTypeId(type);
      if (type === 1) {
        const startOfWeek = currentMonth.clone().startOf("week");
        const days = [];
        const nextWeekDays = [];
        for (let i = 0; i <= 4; i++) {
          const day = startOfWeek.clone().add(i, "days");
          days.push(day);
        }
        setWeekAll(days);
        // } else {
        //  setCurrentWeek(currentWeek);
      }
    }
    // if (type === 2) {
    //   setStartDate(new Date(2024, 6, 1));
    //   setEndDate(new Date(2024, 6, 7));
    // } else {
    //   setStartDate(new Date(2024, 1, 1));
    //   setEndDate(new Date(2024, 12, 7));
    // }
    // transformDataByWeek(events, startDate, endDate);
  };

  const handleMicosoftAuthentication = async () => {
    const codeVerifier = "TamtamPro"
    const codeChallenge = "s_cIBDDUii-glYdPc1uDytmzxtLCXOzccC8NG6wIlcs";

    const authorizationUrl = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=User.Read OnlineMeetings.ReadWrite Calendars.ReadWrite&state=${Date.now().toString(36)}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    const width = 500;
    const height = 600;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);

    const popup = window.open(authorizationUrl, 'Microsoft OAuth', `width=${width},height=${height},top=${top},left=${left}`);

    if (!popup) {
      alert('Popup blocked! Please allow popups for this website.');
    }

    const pollPopup = setInterval(() => {

      if (popup.closed) {
        clearInterval(pollPopup);
      } else {
        try {
          if (popup.location.href.includes(REDIRECT_URI)) {
            const params = new URLSearchParams(popup.location.search);
            const code = params.get('code');

            if (code) {
              clearInterval(pollPopup);
              popup.close();
              exchangeCodeForTokens(code, codeVerifier)
                .then(async (response) => {
                  // Handle successful token exchange (e.g., store tokens, make API calls, etc.)
                  const accessToken = response.access_token;
                  const refreshToken = response.refresh_token;
                  const expirationDate = new Date((new Date()).getTime() + response.expires_in * 1000).toISOString();
                  localStorage.setItem('ms_api_access_token', accessToken);
                  localStorage.setItem('ms_api_refresh_token', refreshToken);
                  localStorage.setItem('ms_api_expires_at', expirationDate)

                  setPlannerConfiguration({
                    accessToken,
                    refreshToken,
                    expirationDate
                  })
                })
                .catch((err) => {
                  console.error('Error exchanging code for tokens:', err);
                });
            }
          }
        } catch (error) {
          // Handle cross-origin access errors
          if (error.name !== 'SecurityError') {
            console.error(error);
          }
        }
      }
    }, 1000);
  }

  return (
    <div className="planner-calendar">
      <div className="filters_content">
        <div className=" filters  line_space_content">
          <div className="filters ">
            <div className="calender_type">
              {renderCustomAddOn(
                selectedCalendarTypeId,
                calendarTabs,
                "calendar"
              )}
            </div>
            <div style={{ marginLeft: "12px" }}>
              {renderCustomAddOn(selectedPeriodTypeId, periodTabs, "period")}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px'}}>
            <button
              className="line_space_content"
              onClick={() => {
                setIsBackupEventsVisible(!isBackupEventsVisible);
              }}
            >
              <IconEye size={10} /> Événement binome
            </button>

            {plannerConfiguration === null && (
              <div>
                <Button
                  style={{ display: 'flex', gap: '6px', alignItems: 'center'}}
                  onClick={handleMicosoftAuthentication}
                >
                  <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" itemprop="logo" itemscope="itemscope" width={14} height={14}>
                    <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
                    <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
                    <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
                    <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
                  </svg>
                  Associer Outlook
                </Button>
              </div>
            )}

            <button
              className="line_space_content"
              onClick={() => {
                setOpenAddEvent(true);
                setSelectedEventId(null);
              }}
            >
              <IconPlus size={10} /> Ajouter un événement
            </button>
          </div>
        </div>
      </div>
      {eventsPlanner.isFetching ? (
        <div className="ripple_loading">
          <img src={"/img/Ripple-1s-150px.svg"} alt="" />
        </div>
      ) : (
        <>
          {selectedCalendarTypeId === 1 ? (
            <CalendarPage
              token={token}
              organizationId={organizationId}
              setOpenAddEvent={setOpenAddEvent}
              setSelectedDateEvent={setSelectedDateEvent}
              renderDurationDisplay={renderDurationDisplay}
              formatDuration={formatDuration}
              timeSheetByDays={timeSheetByDays}
              timeSheetNFByDays={timeSheetNFByDays}
              holidays={holidays}
              durationsByDay={durationsByDay}
              workingHours={workingHours}
              dayMapping={dayMapping}
              events={events}
              backupEvents={backupEvents}
              setEvents={setEvents}
              selectedPeriodTypeId={selectedPeriodTypeId}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              handlePreviousWeek={handlePreviousWeek}
              handleNextWeek={handleNextWeek}
              weeks={weeks}
              currentDate={currentDate}
              currentWeekIndex={currentWeekIndex}
              weekAll={weekAll}
              collapsedWeeks={collapsedWeeks}
              setCollapsedWeeks={setCollapsedWeeks}
              setSelectedCategory={setSelectedCategory}
              selectedDateEvent={selectedDateEvent}
              selectedDurationHours={selectedDurationHours}
              setSelectedDurationHours={setSelectedDurationHours}
              setSelectedDurationTravelHours={setSelectedDurationTravelHours}
              setSelectedDuration={setSelectedDuration}
              selectedDurationMin={selectedDurationMin}
              setSelectedDurationMin={setSelectedDurationMin}
              setSelectedDurationTravelMin={setSelectedDurationTravelMin}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedPlaceRDV={selectedPlaceRDV}
              setSelectedPlaceRDV={setSelectedPlaceRDV}
              selectedDescription={selectedDescription}
              setSelectedDescription={setSelecteddescription}
              setSelectedClient={setSelectedClient}
              setSelectedTypeEvent={setSelectedTypeEvent}
              setSelectedTitleEvent={setSelectedTitleEvent}
              setSelectedEventId={setSelectedEventId}
              selectedMeetingInfo={selectedMeetingInfo}
              setSelectedMeetingInfo={setSelectedMeetingInfo}
              isBackupEventsVisible={isBackupEventsVisible}
            />
          ) : (
            <ListView
              selectedParentId={selectedParentId}
              setSelectedParentId={setSelectedParentId}
              childrens={childrens}
              setChildrens={setChildrens}
              schemaTypeTabs={schemaTypeTabs}
              selectedShemaType={selectedShemaType}
              setSelectedShemaType={setSelectedShemaType}
              transformedData={transformedData}
              transformedDataClients={transformedDataClients}
              formatDuration={formatDuration}
              IconChevronDown={IconChevronDown}
              handleNextWeek={handleNextWeek}
              handlePreviousWeek={handlePreviousWeek}
              startDate={startDate}
              endDate={endDate}
              totalTimeByWeek={totalTimeByWeek}
              totalRealTimeByWeek={totalRealTimeByWeek}
              totalRealTimeNFByWeek={totalRealTimeNFByWeek}
              overtimeByWeek={overtimeByWeek}
            />
          )}
          {openAddEvent && (
            <SideBarPlanner
              token={token}
              year={year}
              organizationId={organizationId}
              collaboratorId={collaborator.id}
              avatar={collaborator.avatar}
              events={events}
              setEvents={setEvents}
              collaboratorName={collaborator.name}
              clientOptions={clientOptions}
              taskCategoriesOptions={taskCategoriesOptions}
              statusOptions={statusOptions}
              selectedClient={selectedClient}
              selectedEventId={selectedEventId}
              selectedCategory={selectedCategory}
              selectedStatus={selectedStatus}
              selectedDescription={selectedDescription}
              selectedDurationHours={selectedDurationHours}
              selectedDurationTravelHours={selectedDurationTravelHours}
              setSelectedTypeEvent={setSelectedTypeEvent}
              setSelectedTitleEvent={setSelectedTitleEvent}
              setSelectedCategory={setSelectedCategory}
              setSelectedClient={setSelectedClient}
              setSelectedStatus={setSelectedStatus}
              setSelectedDurationHours={setSelectedDurationHours}
              setSelectedDurationTravelHours={setSelectedDurationTravelHours}
              setSelectedDurationMin={setSelectedDurationMin}
              setSelectedDurationTravelMin={setSelectedDurationTravelMin}
              setSelectedPlaceRDV={setSelectedPlaceRDV}
              setOpenAddEvent={setOpenAddEvent}
              setSelectedDateEvent={setSelectedDateEvent}
              setSelecteddescription={setSelecteddescription}
              selectedDurationMin={selectedDurationMin}
              selectedDurationTravelMin={selectedDurationTravelMin}
              selectedPlaceRDV={selectedPlaceRDV}
              typesEventDivIsVisible={typesEventDivIsVisible}
              clientsDivIsVisible={clientsDivIsVisible}
              categoriesDivIsVisible={categoriesDivIsVisible}
              statusDivIsVisible={statusDivIsVisible}
              openAddEvent={openAddEvent}
              selectedDateEvent={selectedDateEvent}
              selectedTypeEvent={selectedTypeEvent}
              selectedTitleEvent={selectedTitleEvent}
              budgetProgression={budgetProgression}
              saveCollaboratorPlanner={saveCollaboratorPlanner}
              responsables={responsables}
              selectedMeetingUrl={selectedMeetingInfo?.url}
              setSelectedMeetingInfo={setSelectedMeetingInfo}
              onPlannerConfigurationClick={plannerConfiguration !== null ? null : handleMicosoftAuthentication}
              isSaving={isSaving}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Planner;
