import React, { Fragment, useEffect, useState } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import { renderAvatar } from "../../../utils";
import { IconClose, CalendarIcon } from "../../common/Icons";
import { TiArrowSortedDown } from "react-icons/ti";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Button, DatePicker, Input, Switch, TimePicker, Tooltip } from "antd";
import { getClientContactInfo } from "../../../api";
import { useSelector } from "react-redux";
moment.locale("fr");

const SideBarPlanner = ({
  collaboratorName,
  selectedEventId,
  selectedClient,
  setSelectedClient,
  selectedCategory,
  setSelectedCategory,
  selectedDateEvent,
  setSelectedDateEvent,
  selectedDurationHours,
  selectedDurationTravelHours,
  setSelectedDurationHours,
  setSelectedDurationTravelHours,
  selectedDurationMin,
  selectedDurationTravelMin,
  setSelectedDurationMin,
  setSelectedDurationTravelMin,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  clientOptions,
  taskCategoriesOptions,
  statusOptions,
  events,
  setEvents,
  setOpenAddEvent,
  setSelectedTypeEvent,
  selectedTypeEvent,
  selectedTitleEvent,
  setSelectedTitleEvent,
  setSelecteddescription,
  budgetProgression,
  responsables,
  avatar,
  saveCollaboratorPlanner,
  lng,
  selectedMeetingUrl,
  setSelectedMeetingInfo,
  onPlannerConfigurationClick,
  isSaving,
}) => {
  const {token, navCommunity} = useSelector((state) => state.auth);
  const [typesEventDivIsVisible, setTypesEventDivIsVisible] = useState(null);
  const [clientsDivIsVisible, setClientsDivIsVisible] = useState(null);
  const [categoriesDivIsVisible, setCategoriesDivIsVisible] = useState(null);
  const [statusDivIsVisible, setStatusDivIsVisible] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [isMeetinglinkCopied, setIsMeetingLinkCopied] = useState(false);
  const [clientEmailAddress, setClientEmailAddress] = useState(null);
  const ECART_MAP = {
    NEGATIVE: "tag-warning",
    POSITIVE: "tag-danger",
    EQUAL: "tag-success",
  };
  const typeEvent = [
    {
      id: 1,
      label: "task",
      title: "task",
    },
    {
      id: 2,
      label: "meeting",
      title: "meeting",
    },
    {
      id: 3,
      label: "management",
      title: "management",
    },
  ];
  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filteredOptions = clientOptions.filter((item) =>
    item.label.toLowerCase().includes(filterValue.toLowerCase())
  );

  // useEffect(() => {
  //   if (selectedClient && selectedClient.value) {
  //     (async function getClientContactInformation() {
  //       const response = await getClientContactInfo({
  //         token,
  //         organizationId: navCommunity.id,
  //         clientId: selectedClient.value
  //       });
  //       setClientEmailAddress(response.data?.data?.email?.email);
  //     })();
  //   }
  // }, [selectedClient])

  const renderMeetingLinkInput = () => {
    if (!selectedMeetingUrl) return null;

    return (
      <div style={{width: '100%'}}>
        <div className="title">Lien de meet</div>
        <div className="meeting_link">
          <Tooltip title={`${isMeetinglinkCopied ? "Le lien est copié" : "Copier dans le presse-papiers"}`}>
            <button className="meeting_link_button" onClick={() => {
              navigator.clipboard.writeText(selectedMeetingUrl);
              setIsMeetingLinkCopied(true);
              setTimeout(() => {
                setIsMeetingLinkCopied(false);
              }, 1500);
            }}>
              {isMeetinglinkCopied ? (
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24">
                  <path stroke="#63a113" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 11.917 9.724 16.5 19 7.5"/>
                </svg>
              ) : (
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill='currentColor' viewBox="0 0 24 24">
                  <path  fill-rule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-6 8a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
                </svg>
              )}
            </button>
          </Tooltip>
          <textarea
            disabled
            className={"rectangular_select_header meeting_link_input"}
            rows="5">{selectedMeetingUrl}</textarea>
        </div>
      </div>
    );
  }

  const renderAddTaskEevent = (selectedId, items, type) => {
    return (
      <div>
        <div className="title">Dossier client</div>
        <div className={"rectangular_select"} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setClientsDivIsVisible(!clientsDivIsVisible)}
          >
            <span>{selectedClient ? selectedClient.label : "Select"}</span>
            <TiArrowSortedDown />
          </div>
          {clientsDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <input
                type="text"
                value={filterValue}
                onChange={handleFilterChange}
                placeholder="Filter options..."
                className={"rectangular_select_filter"}
              />
              <div className={"rectangular_select_body_options text_tranform"}>
                {filteredOptions.map((item, index) => (
                  <div
                    key={index}
                    className={"rectangular_select_body_option"}
                    onClick={() => {
                      setSelectedClient(item);
                      setClientsDivIsVisible(false);
                    }}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* {!clientEmailAddress ? (
            selectedClient && (
              <p className="client_email_notice client_email_notice_nok">
                Ce client n'a pas d'adresse e-mail.
              </p>
            )
          ): (
            <p className="client_email_notice client_email_notice_ok">
              L'address e-mail du client est : <a href={`mailto: ${clientEmailAddress}`}><i>{clientEmailAddress}</i></a>
            </p>
          )} */}
        </div>
        <div className="title">Catégorie</div>
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setCategoriesDivIsVisible(!categoriesDivIsVisible)}
          >
            <span>
              {selectedCategory ? selectedCategory.label : _("Select")}
            </span>
            <TiArrowSortedDown />
          </div>
          {categoriesDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {taskCategoriesOptions.map((item, index) => {
                  if (!item) {
                    return;
                  }
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedCategory(item);
                        setCategoriesDivIsVisible(false);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {selectedCategory && selectedClient && (
          <div className="">
            <div className="upper-frame">
              <div className="left-panel" style={{ width: "100%" }}>
                <div className="list-frame">{renderTaskListII()}</div>
              </div>
            </div>
          </div>
        )}
        <div className="line_space_content">
          <div>
            <div className="title">Date</div>
            <div className={"calendar_select"}>
              {/* <Calendar
                      showIcon
                      dateFormat="dd/mm/yy"
                      locale={lng}
                      className="p-datepicker-sidebar"
                      value={
                        editedPlan ? editedPlan.deadLine : activePlan.deadLine
                      }
                      onChange={(e) => {
                        editedPlan
                          ? setEditedPlan({
                              ...editedPlan,
                              deadLine: e.value,
                            })
                          : setEditedPlan({
                              ...activePlan,
                              deadLine: e.value,
                            });
                      }}
                      icon={<CalendarIcon fill="#6D7F92" />}
                    ></Calendar> */}
              {/* <Calendar
                showIcon
                dateFormat="dd/mm/yy"
                locale={lng}
                // value={"select"}
                value={moment(selectedDateEvent, "Y-M-D").toDate()}
                className="p-datepicker-sidebar"
                panelClassName="large_calendar"
                onChange={(e) => setSelectedDateEvent(e.value)}
                icon={<CalendarIcon fill="#6D7F92" />}
              /> */}
              <Calendar
                showIcon
                dateFormat="dd/mm/yy"
                locale={lng}
                // value={"select"}
                value={selectedDateEvent}
                className="p-datepicker-sidebar"
                panelClassName="large_calendar"
                onChange={(e) => {
                  setSelectedDateEvent(e.value)
                }}
                icon={<CalendarIcon fill="#6D7F92"/>}
                showTime
                hourFormat="24"
              />
            </div>
          </div>
          <div>
            <div className="title">Durée de la tache</div>

            <div className="line_space_content">
              <div className="input-group">
                <input
                  id="hours"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="12"
                  value={selectedDurationHours}
                  onChange={(event) =>
                    setSelectedDurationHours(event.target.value)
                  }
                />
                <span className="label_input">Heure(s)</span>
              </div>
              <div className="input-group no-margin-right">
                <input
                  id="minutes"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDurationMin}
                  onChange={(event) =>
                    setSelectedDurationMin(event.target.value)
                  }
                />
                <span className="label_input">Minute(s)</span>
              </div>
            </div>
          </div>
        </div>
        <div className="title">Statut de progression</div>
        {/* {renderRectangularSelect(
          selectedCategory,
          taskCategoriesOptions,
          categoriesDivIsVisible,
          setSelectedCategory
        )} */}
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setStatusDivIsVisible(!statusDivIsVisible)}
          >
            {selectedStatus ? selectedStatus : _("Select")}
            <TiArrowSortedDown />
          </div>
          {statusDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {statusOptions.map((item, index) => {
                  if (!item) {
                    return;
                  }
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedStatus(item.label);
                        setStatusDivIsVisible(false);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="title">Description</div>

        <textarea
          className="description"
          value={selectedDescription}
          rows="3"
          placeholder="Ajoutez votre description ici ..."
          onChange={(event) => {
            setSelecteddescription(event.target.value);
          }}
        />

        {renderMeetingLinkInput()}
      </div>
    );
  };

  const renderAddMeetingEvent = (selectedId, items, type) => {
    return (
      <div>
        {selectedTypeEvent === "meeting" && (
          <div className="radio-container">
            <input
              type="radio"
              id="option1"
              name="options"
              value="option1"
              style={{ border: "2px solid red" }}
              checked={selectedPlaceRDV === "remote"}
              onChange={() => setSelectedPlaceRDV("remote")}
            />
            <div className="place"> À distance</div>
            <input
              type="radio"
              id="option2"
              name="options"
              value="option1"
              checked={selectedPlaceRDV === "onSite"}
              onChange={() => setSelectedPlaceRDV("onSite")}
            />
            <div className="place"> Sur place</div>
          </div>
        )}
        <div className="title">
          {selectedTypeEvent === "meeting" ? "Dossier client" : "Title"}
        </div>

        {selectedTypeEvent === "meeting" ? (
          <div className={"rectangular_select"} style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start'}}>
            <div
              className={"rectangular_select_header"}
              onClick={() => setClientsDivIsVisible(!clientsDivIsVisible)}
            >
              <span>{selectedClient ? selectedClient.label : _("Select")}</span>
              <TiArrowSortedDown />
            </div>
            {clientsDivIsVisible && (
              <div className={"rectangular_select_body"}>
                <div className={"rectangular_select_body_options"}>
                  {clientOptions.map((item, index) => {
                    if (!item) {
                      return;
                    }
                    return (
                      <div
                        key={index}
                        className={"rectangular_select_body_option"}
                        onClick={() => {
                          setSelectedClient(item);
                          setClientsDivIsVisible(false);
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* {!clientEmailAddress ? (
              selectedClient && (
                <p className="client_email_notice client_email_notice_nok">
                  Ce client n'a pas d'adresse e-mail.
                </p>
              )
            ): (
              <p className="client_email_notice client_email_notice_ok">
                L'address e-mail du client est : <a href={`mailto: ${clientEmailAddress}`}><i>{clientEmailAddress}</i></a>
              </p>
            )} */}
          </div>
        ) : (
          <div className={"rectangular_select"}>
            <input
              type="text"
              className="rectangular_select_header"
              // className={classNames(
              //   styles.input_box,
              //   styles.label,
              //   (column.label === "Total" || !item[column.label]["checked"]) &&
              //     styles.disabled,
              //   column.label === "Total" && styles.title_disabled
              // )}
              // value={item[column.label]["value_heure"] + " h"}
              // required={true}
              // disabled={!item[column.label]["checked"]}
              // style={{
              //   margin: item.label === "Total" && "auto",
              // }}
              value={selectedTitleEvent}
              onChange={(event) => setSelectedTitleEvent(event.target.value)}
            ></input>
          </div>
        )}
        {selectedTypeEvent !== "meeting" && (
          <>
            <div className="title">Statut de progression</div>
            {/* {renderRectangularSelect(
             selectedCategory,
             taskCategoriesOptions,
             categoriesDivIsVisible,
             setSelectedCategory
           )} */}
            <div className={"rectangular_select"}>
              <div
                className={"rectangular_select_header"}
                onClick={() => setStatusDivIsVisible(!statusDivIsVisible)}
              >
                {selectedStatus ? selectedStatus : _("Select")}
                <TiArrowSortedDown />
              </div>
              {statusDivIsVisible && (
                <div className={"rectangular_select_body"}>
                  <div className={"rectangular_select_body_options"}>
                    {statusOptions.map((item, index) => {
                      if (!item) {
                        return;
                      }
                      return (
                        <div
                          key={index}
                          className={"rectangular_select_body_option"}
                          onClick={() => {
                            setSelectedStatus(item.label);
                            setStatusDivIsVisible(false);
                          }}
                        >
                          {item.label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className="line_space_content">
          <div>
            <div className="title">Date</div>
            <div className={"calendar_select"}>
              <Calendar
                showIcon
                dateFormat="dd/mm/yy"
                locale={lng}
                // value={"select"}
                value={selectedDateEvent}
                className="p-datepicker-sidebar"
                panelClassName="large_calendar"
                onChange={(e) => {
                  setSelectedDateEvent(e.value)
                }}
                icon={<CalendarIcon fill="#6D7F92"/>}
                showTime
                hourFormat="24"
              />
            </div>
          </div>
          <div>
            <div className="title">Durée de la tache</div>

            <div className="line_space_content">
              <div className="input-group">
                <input
                  id="hours"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="12"
                  value={selectedDurationHours}
                  onChange={(event) =>
                    setSelectedDurationHours(event.target.value)
                  }
                />
                <span className="label_input">Heure(s)</span>
              </div>
              <div className="input-group no-margin-right">
                <input
                  id="minutes"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDurationMin}
                  onChange={(event) =>
                    setSelectedDurationMin(event.target.value)
                  }
                />
                <span className="label_input">Minute(s)</span>
              </div>
            </div>
          </div>
        </div>
        {selectedTypeEvent === "meeting" && (
          <div>
            <div className="title">Durée du trajet</div>

            <div className="line_space_content">
              <div className="input-group">
                <input
                  id="hours"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="12"
                  value={selectedDurationTravelHours}
                  onChange={(event) =>
                    setSelectedDurationTravelHours(event.target.value)
                  }
                />
                <span className="label_input">Heure(s)</span>
              </div>
              <div className="input-group no-margin-right">
                <input
                  id="minutes"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDurationTravelMin}
                  onChange={(event) =>
                    setSelectedDurationTravelMin(event.target.value)
                  }
                />
                <span className="label_input">Minute(s)</span>
              </div>
            </div>
          </div>
        )}
        <div className="title">Description</div>
        <textarea
          className="description"
          value={selectedDescription}
          rows="3"
          placeholder="Ajoutez votre description ici ..."
          onChange={(event) => {
            setSelecteddescription(event.target.value);
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {renderMeetingLinkInput()}

          {onPlannerConfigurationClick && (
            <div>
              <Button
                style={{ display: 'flex', gap: '6px', alignItems: 'center'}}
                onClick={onPlannerConfigurationClick}
              >
                <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" itemprop="logo" itemscope="itemscope" width={14} height={14}>
                  <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
                  <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
                  <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
                  <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
                </svg>
                Configurer
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleSaveEvent = () => {
    let date;
    if (
      typeof selectedDateEvent === "string" ||
      selectedDateEvent instanceof String
    ) {
      date = new Date(selectedDateEvent);
    } else {
      date = selectedDateEvent;
    }

    let formattedDate = null;
    let formattedDateAA, formattedDateLast;
    if (!isNaN(date)) {
      formattedDate = {
        date: date.toISOString().replace("T", " ").replace("Z", ".000000"),
        timezone_type: 3,
        timezone: "UTC",
      };
      const localOffset = date.getTimezoneOffset();
      const adjustedDate = new Date(date.getTime() + localOffset * 60000);

      formattedDateAA = adjustedDate
        .toISOString()
        .replace("T", " ")
        .replace("Z", ".000000");

      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000000`;
      formattedDateLast = {
        date: formattedDate,
        timezone_type: 3,
        timezone: "UTC",
      };
    }
    const duration =
      selectedDurationHours && selectedDurationMin
        ? parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin)
        : selectedDurationHours
        ? selectedDurationHours * 60
        : selectedDurationMin
        ? selectedDurationMin
        : 0;

    const newEvent = {
      id: selectedEventId,
      type: selectedTypeEvent,
      start: moment(selectedDateEvent, "Y-M-D").toDate(),
      // start: selectedDateEvent,
      date: formattedDateLast,
      duration: duration,
      meetingPlace: selectedPlaceRDV,
      client_id: selectedClient ? selectedClient.value : null,
      client_name: selectedClient ? selectedClient.label : null,
      category_name: selectedCategory ? selectedCategory.label : null,
      category_id: selectedCategory ? selectedCategory.value : null,
      description: selectedDescription,
      status: selectedStatus,
    };
    saveCollaboratorPlanner(newEvent);
  };

  const renderTaskListII = () => {
    if (!budgetProgression || !responsables) {
      return <div>Loading...</div>;
    }

    const selectedCategoryDetails =
      budgetProgression.categories[selectedCategory.label];
    // if (!selectedCategoryDetails || !selectedCategoryDetails.details) {
    //   return <div>Aucun détail disponible pour cette catégorie.</div>;
    // }

    return (
      <Fragment>
        <div className="list-frame_item_body">
          <div className="header">
            <div className="infos">
              <div className="avatar"></div>
              <div></div>
              <div></div>
            </div>

            <div className="tag" style={{ marginLeft: "47%" }}>
              <span className="progression_header">{_("Budgétés")}</span>
              <div className="tag">
                {/* {parseFloat(budgetProgression.categories[selectedCategory.value].total.budget)}h */}
              </div>
            </div>
            <div className="tag tag-success">
              <span className="progression_header">{_("Réalisés")}</span>{" "}
            </div>
            <div>
              <span className="progression_header">{_("Écart")}</span>{" "}
              <div className="tag"></div>
            </div>
          </div>

          {Object.keys(selectedCategoryDetails.details).map((index) => {
            let detail = selectedCategoryDetails.details[index];
            let responsable = responsables[index];

            // Vérifiez que le responsable existe
            if (!responsable) {
              return null;
            }

            return (
              <div className="line" key={index}>
                <div className="infos">
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: `url(${responsable.avatarUrl})`,
                    }}
                  ></div>
                  <div>{responsable.name}</div>
                  <div>{_(responsable.roles.join("-"))}</div>
                </div>
                <div className="tag">
                  <div>{parseFloat(detail.budget)}</div>
                </div>
                <div className="tag tag-success">
                  <div>{parseFloat(detail.real)}</div>
                </div>
                <div className={`tag ${ECART_MAP[detail.ecart]}`}>
                  <div>
                    {parseFloat(
                      parseFloat(detail.real - detail.budget).toFixed(2)
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  };

  return (
    <div className="fadePage">
      <div className={`sideBar display`}>
        {isSaving && (<div className="overlay">
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" width={100} height={100}/>
          </div>
        </div>)}
        <div className="header">
          <span>
            {selectedEventId ? "Modifier l'événement" : "Ajouter un événement"}
          </span>
          <div
            className="sidebar_header_action"
            onClick={() => {
              setOpenAddEvent(false);
              setSelectedCategory(null);
              setSelectedClient(null);
              setSelectedDateEvent(null);
              setSelectedTypeEvent(null);
              setSelectedTitleEvent(null);
              setSelectedMeetingInfo(null);
              setClientEmailAddress(null);
            }}
          >
            <IconClose size={18} />
          </div>
        </div>
        <div className="sidebar_content">
          <div className="title">Collaborateur assigné</div>
          <div className="sub_title">
            <div className="avatar">{renderAvatar(avatar)}</div>
            <div className="name">{collaboratorName}</div>
          </div>
          <div className="title">Type d’événement</div>
          <div className={"rectangular_select"}>
            <div
              className={"rectangular_select_header"}
              onClick={() => {
                setTypesEventDivIsVisible(!typesEventDivIsVisible);
                setSelectedCategory(null);
                setSelectedClient(null);
                setSelectedTitleEvent(null);
                setSelectedTypeEvent(null);
                setSelectedMeetingInfo(null);
                setClientEmailAddress(null);
              }}
            >
              <span>{selectedTypeEvent ? selectedTypeEvent : _("select")}</span>
              <TiArrowSortedDown />
            </div>
            {typesEventDivIsVisible && (
              <div className={"rectangular_select_body"}>
                <div className={"rectangular_select_body_options"}>
                  {typeEvent.map((item, index) => {
                    if (!item) {
                      return;
                    }
                    return (
                      <div
                        key={index}
                        className={"rectangular_select_body_option"}
                        onClick={() => {
                          setSelectedTypeEvent(item.label);
                          setTypesEventDivIsVisible(false);
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="diviser" />
          {
            // selectedTypeEvent && selectedTypeEvent === "task"
            //   ?
            selectedTypeEvent &&
              (selectedTypeEvent === "task"
                ? renderAddTaskEevent()
                : renderAddMeetingEvent())
          }
        </div>
        <div className="sidebar_footer line_space_content">
          <button
            onClick={() => {
              setOpenAddEvent(false);
              setSelectedCategory(null);
              setSelectedClient(null);
              setSelectedDateEvent(null);
              setSelectedTypeEvent(null);
              setSelectedTitleEvent(null);
              setSelectedMeetingInfo(null);
              setClientEmailAddress(null);
            }}
          >
            Annuler
          </button>
          <button
            className={
              "add " +
              (!selectedTypeEvent ||
              (selectedTypeEvent !== "management" && !selectedClient) ||
              (selectedTypeEvent === "task" && !selectedCategory) ||
              !selectedDateEvent
                ? "disabled"
                : "")
            }
            onClick={() => handleSaveEvent()}
          >
            {selectedEventId ? "Modifier" : "Ajouter"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBarPlanner;
