import { useSelector } from "react-redux";
import _ from "../../i18n";
import { BriefCaseIcon, FlagIcon } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import { CustumedMatrix } from "tamtam-components";
import { getRevisionForfait,getListClient, saveForfaitMissionLetter } from "../../api";
import { useQuery ,useQueryClient } from "react-query";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import styles from "./Forfait.module.scss";
import TTPFilterHorizontalBar from "./TTPFilterHorizontalBar";
import ForfaitSidePanel from "./ForfaitSidePanel";
import TTPTab2 from "../common/Tab/TTPTab2";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import NotAuthorized from "../common/NotAuthorized";

const tabs = [
  {
    id: "INVOICE",
    label: "Facturation",
    subTitle: "Analyse du Facturation"
  },
  {
    id: "IMP_R_F",
    label: "Impacts Réalisés/Facturés",
    subTitle: "Analyse des Impacts Réalisés Vs Facturés"
  },
  {
    id: "VOLUMETRY",
    label: "Volumétrie",
    subTitle: "Volumétrie du Dossier"
  },
  {
    id: "PRESTATION",
    label: "Prestation",
    subTitle: "Analyse des Prestations"
  }
];

const ENCODING_TYPE = [
  { label: "amount_purchases", value: 'Factures d\'achat' },
  { label: "amount_sales", value: 'Factures de vente' },
  { label: "finance_transactions", value: 'Banques' },
];

const ENCODING_ANALYSE_TYPE = [
  { label: "worked_hours", value: 'Heurs realisés (h)' },
  { label: "billed_hours", value: 'Heurs facturés (h)' },
  { label: "minimum_reference_schedule", value: 'Horaire minimum de référence (€)' },
  { label: "effectif_schedule", value: 'Horaire effectif (facturation honoraires / 80€)' },
  { label: "minimum_billing_realized_fee", value: 'Facturation minimum pour les honoraires réalisés (€)' },
  { label: "billing_impact", value: 'Impact sur facturation (€)' },
];
 
const ENCODING_FACTURATION_TYPE = [
  { label: "fee_billing", value: 'Facturation honoraires HTVA (€)' },
  { label: "non_fee_billing", value: 'Facturation hors honoraires HTVA (€)' },
  { label: "global_billing", value: 'Facturation globale HTVA (€)' },
  { label: "paid_by_client", value: 'Payé par le client TVAc (€)' },
  { label: "remaining_to_pay", value: 'Reste à payer TVAc (€)' },
];

const Forfait = () => {
  const { clientID } = useParams();
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const columns = [];
  const [isDataFetching, setIsDataFetching] = useState(false);

  for (let year = currentYear; year >= startYear; year--) {
    columns.push({
      id: year.toString(),
      label: _(year.toString()),
      isEditable: true,
      widthPercentage: 10,
    });
  }
  columns.push({
    id: "mission_letter",
    label: _("Lettre de Mission"),
    isEditable: true,
    widthPercentage: 12,
  })
  const [clientOptions,setClientOptions] =useState([])
  const queryClient = useQueryClient();
  const [client, setClient] = useState(clientID ? clientID : 3);
  const [volumetryData, setVolumetryData] = useState([]);
  const [prestationData, setPrestationData] = useState([]);
  const [facturationData, setFacturationData] = useState([]);
  const [analyseData, setAnalyseData] = useState([]);
  const [missionLetterData, setMissionLetterData] = useState([]);
  const [sidePanelVisible, setSidePanelVisible] = useState(false);
  const [filter, setFilter] = useState({
    tab: tabs[0].id,
    subTitle: tabs[0].subTitle
  });

  const { status, error } = useQuery(
    ["getClientRevisionForfait", token, navCommunity],
    async () => {
      if (token && navCommunity && client) {
        try {
          const response = await getRevisionForfait(token, navCommunity.id, client);
          setVolumetryData(response.data.data.volumetry);
          setPrestationData(response.data.data.prestation);
          setFacturationData(response.data.data.facturation);
          setAnalyseData(response.data.data.analyse);
          const f = response.data.data.facturation.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});
          
          const v = response.data.data.volumetry.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});
          
          const p = response.data.data.prestation
            .filter(i => i.type !== "")
            .reduce((acc, i) => {
              acc[i.type] = i.value[3].amount;
              return acc;
            }, {});
          
          const imp = response.data.data.analyse.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});
          
         setMissionLetterData(prevState => ({
          ...prevState,
          ...f,
          ...v,
          ...p,
          ...imp
        }));
          
          return response.data.data.volumetry;
        } catch (e) {
          throw new Error(e.message);
        }
      }
    },
    { enabled: !!token && !!navCommunity }
  );

  const { status: statusList, error: errorList } = useQuery(
    ["getListClient", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getListClient(token, navCommunity.id);
          setClientOptions(response.data.data);
          return response.data.data;
        } catch (e) {
          throw new Error(e.message);
        }
      }
    },
    { enabled: !!token && !!navCommunity }
  );

  useEffect(() => {
      if (token && navCommunity && client) {
        setIsDataFetching(true);
        getRevisionForfait(token, navCommunity.id, client).then(response =>{
          setVolumetryData(response.data.data.volumetry);
          setPrestationData(response.data.data.prestation);
          setFacturationData(response.data.data.facturation);
          setAnalyseData(response.data.data.analyse);
          const f = response.data.data.facturation.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});
          
          const v = response.data.data.volumetry.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});
          
          const p = response.data.data.prestation
            .filter(i => i.type !== "")
            .reduce((acc, i) => {
              acc[i.type] = i.value[3].amount;
              return acc;
            }, {});
          
          const imp = response.data.data.analyse.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});
          
          setMissionLetterData(prevState => ({
            ...prevState,
            ...f,
            ...v,
            ...p,
            ...imp
          }));
        })
        .catch((e) => {
          throw new Error(e.message);
        })
        .finally(() => {
            setIsDataFetching(false);
        });
    }
  }, [client, queryClient, token, navCommunity]);

  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};

  if (preferences.allowAccessForfait === "0") {
    return <NotAuthorized />;
  }

  const handleInputChange = (value, column) => {
    setMissionLetterData({
      ...missionLetterData,
      [column]: parseFloat(value)
    })
  };

  const handleOnEnter = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  const handleSave = () => {
    const data = {
      client,
      organization: navCommunity && navCommunity.id,
      params: JSON.stringify(missionLetterData),
      token,
    };

    console.log("dd", data.params);

    saveForfaitMissionLetter(data)
      .then((resp) => {
        if (resp.data.result === "OK") {
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const handleColumnsVolumetry = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              column.id !== "mission_letter" ? (
                <span key={index} style={{padding: 5}}>
                  {column.id == data.label ? data.amount : ""} 
                </span>
              ) : (
                column.id == data.label ? (
                  <div className={styles.editable_zone}>
                    <input
                      type="text"
                      className={styles.editable_text}
                      style={{ textAlign: "center", width: "50%", marginLeft: -5 }}
                      defaultValue={missionLetterData[line.type]}
                      required={true}
                      onChange={(event) =>
                        handleInputChange(
                          event.target.value,
                          line,
                          line.type
                        )
                      }
                      onKeyDown={handleOnEnter}
                    />
                    <Tooltip
                      defaultOpen
                      title="Click Enter to save"
                    >
                      <i className={`${styles.icon_info_circle} pi pi-info-circle`} />
                    </Tooltip>
                  </div>
                ) : ""
              )
            ))}
          </div>
        </div>
      </div>
    );
  };
  const convertToHoursMinutes = (value) => {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return `${hours}h ${minutes}m`;
  };
  const handleColumnsPrestation = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              column.id !== "mission_letter" ? (
                <span key={index} style={{padding: 5}}>
                  {column.id == data.label ? convertToHoursMinutes(data.amount) : ""}
                </span>
                ) : (
                  column.id == data.label ? (
                    <div className={styles.editable_zone}>
                      <input
                        type="text"
                        className={styles.editable_text}
                        style={{ textAlign: "center", width: "50%", marginLeft: -5 }}
                        defaultValue={missionLetterData[line.type]}
                        required={true}
                        onChange={(event) =>
                          handleInputChange(
                            event.target.value,
                            line.type
                          )
                        }
                        onKeyDown={handleOnEnter}
                      />
                       <Tooltip
                        defaultOpen
                        title="Click Enter to save"
                      >
                        <i className={`${styles.icon_info_circle} pi pi-info-circle`} />
                      </Tooltip>
                    </div>
                  ) : ""
                )
            ))}
          </div>
        </div>
      </div>
    );
  };
  const handleColumnsFacturation = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              column.id !== "mission_letter" ? (
                <span key={index} style={{padding: 5}}>
                  {column.id == data.label ? data.amount : ""} 
                </span>
              ) : (
                column.id == data.label ? (
                  <div className={styles.editable_zone}>
                    <input
                      type="text"
                      className={styles.editable_text}
                      style={{ textAlign: "center", width: "50%", marginLeft: -5 }}
                      defaultValue={missionLetterData[line.type]}
                      required={true}
                      onChange={(event) =>
                        handleInputChange(
                          event.target.value,
                          line.type
                        )
                      }
                      onKeyDown={handleOnEnter}
                    />
                     <Tooltip
                      defaultOpen
                      title="Click Enter to save"
                    >
                      <i className={`${styles.icon_info_circle} pi pi-info-circle`} />
                    </Tooltip>
                  </div>
                ) : ""
              )
            ))}
          </div>
        </div>
      </div>
    );
  };
  const handleColumnsAnalyse = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
            <style>
      {`
        .CustumedMatrix_matrix_body_line__11lbb:first-child {
          border-bottom: 1px solid #d8dde2;
        }
          .CustumedMatrix_matrix__10BiN {
          background-color: white;
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #29394d;
        }
      `}
    </style>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) => (
              column.id !== "mission_letter" ? (
                <span
                  key={index}
                  style={{
                    padding: 5,
                    color: line.type === "billing_impact"
                      ? parseFloat(data.amount) < 0
                        ? "red"
                        : "green" 
                      : ""
                    }}
                >
                  {column.id == data.label ? data.amount : ""} 
                </span>
              ) : (
                column.id == data.label ? (
                  <div className={styles.editable_zone}>
                    <input
                      type="text"
                      className={styles.editable_text}
                      style={{ textAlign: "center", width: "50%", marginLeft: -5 }}
                      defaultValue={missionLetterData[line.type]}
                      required={true}
                      onChange={(event) =>
                        handleInputChange(
                          event.target.value,
                          line.type
                        )
                      }
                      onKeyDown={handleOnEnter}
                    />
                     <Tooltip
                      defaultOpen
                      title="Click Enter to save"
                    >
                      <i className={`${styles.icon_info_circle} pi pi-info-circle`} />
                    </Tooltip>
                  </div>
                ) : ""
              )
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleTitleVolumetry = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{marginTop: 20}}>
          {ENCODING_TYPE.map(data=>{
            if(data.label === title.type) return data.value;
          })}
        </div>
      </div>
    </Fragment>
  );

  const handleTitlePrestation = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{marginTop: 20}}>
         {title.type}
        </div>
      </div>
    </Fragment>
  );
  const handleTitleFacturation = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{marginTop: 20}}>
          {ENCODING_FACTURATION_TYPE.map(data=>{
            if(data.label === title.type) return data.value;
          })}
          </div>
      </div>
    </Fragment>
  );
  const handleTitleAnalyse = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{marginTop: 20}}>
         {ENCODING_ANALYSE_TYPE.map(data => {
            if(data.label === title.type) return data.value;
          })}
        </div>
      </div>
    </Fragment>
  );

  const renderCustumeMatrix = () => {
    switch (filter.tab) {
      case "INVOICE":
      return (
        <CustumedMatrix
          columns={columns}
          items={facturationData}
          handleCellule={handleColumnsFacturation}
          handleTitle={handleTitleFacturation}
          headerTagBG={"#FFFFFF"}
        />
      );
      case "IMP_R_F":
        return (
          <CustumedMatrix
            columns={columns}
            items={analyseData}
            handleCellule={handleColumnsAnalyse}
            handleTitle={handleTitleAnalyse}
            headerTagBG={"#FFFFFF"}
          />
        )
      case "VOLUMETRY":
        return (
          <CustumedMatrix
            columns={columns}
            items={volumetryData}
            handleCellule={handleColumnsVolumetry}
            handleTitle={handleTitleVolumetry}
            headerTagBG={"#FFFFFF"}
          />
        )
      case "PRESTATION":
        return (
          <CustumedMatrix
            columns={columns}
            items={prestationData}
            handleCellule={handleColumnsPrestation}
            handleTitle={handleTitlePrestation}
            headerTagBG={"#FFFFFF"}
          />
        )
      default: <></>
    }
  }

  return (
    <div className="page-content">
      <PageHeader
        header={_("revisionForfait")}
        subHeader={_(`${filter.subTitle}`)}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}
      />

      <div className="tab-container">
        <TTPTab2
          tabs={tabs}
          activeTab={filter.tab}
          width="fit-content"
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id, subTitle: item.subTitle });
          }}
        />
      </div>

      <div className={styles.toggle_btn} onClick={() => setSidePanelVisible(true)}>
        <FlagIcon size={18} className={styles.flag_icon} />
        <span>{_("forfaitAnalysisProposals")}</span>
      </div>

      <ForfaitSidePanel
        visible={sidePanelVisible}
        onClose={() => setSidePanelVisible(false)}
        client={client}
        organization={navCommunity ? navCommunity.id : null}
        token={token}
        year={currentYear}
      />
      
      <div className="ttp-tab-container">
        <div className="content-body large-content" style={{ padding: "0 0 0" }}>
          {status === 'success' && (
            <>
              <TTPFilterHorizontalBar
                client={client}
                setClient={setClient}
                button={true}
                fetching={false}
                filters={[
                  {
                    type: "SELECT",
                    options: {
                      placeholder: "Client",
                      options: clientOptions,
                      values: client ? [client] : [],
                      isMulti: false,
                      isClearable: true,
                      isSimple: true,
                      action: setClient
                    },
                    grid: 12,
                    color: "#0a0a0a"
                  }
                ]}
              />
              {client ? (
                isDataFetching ? (
                  <div className="ripple_loading">
                    <img src={"/img/Ripple-1s-150px.svg"} alt="" />
                  </div>
                ) : (
                  <div style={{marginTop: 25}}>
                    {renderCustumeMatrix()}
                  </div>
                )
              ) : (
                <div className="no_data">
                  <div className="title">{_("Aucun client sélectionné !")}</div>
                  <div className="subTitle">{_("Veuillez choisir un client")}</div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        {status === 'loading' && <p>Loading data...</p>}
        {status === 'error' && <p>Error: {error.message}</p>}
      </div>
    </div>
  );
};

export default Forfait;
